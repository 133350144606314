<template>
  <table class="table align-items-center mb-0" aria-describedby="Generic table view">
    <thead>
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              :class="{ 'text-center': column.checkbox, 'ps-2': !column.checkbox }"
            >
            <input v-if="column.checkbox" type="checkbox" @click="switchAll()" aria-label="checkbox for selecting all rows">
            <span v-else>{{column.label}}</span>
          </th>
        </slot>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td v-for="column in columns" :key="column" :class="{ 'text-center': column.checkbox }">
            <input v-if="column.checkbox" type="checkbox" ref="checkbox"
                :id="itemValue(item, column)" :value="itemValue(item, column)"
                v-model="list" aria-label="checkbox for each row"
              >
            <router-link v-else-if=column.link :to="itemLink(item, column)" class="text-secondary text-xs mb-0" data-toggle="tooltip" data-original-title="Edit">
              {{ itemValue(item, column) }}
            </router-link>
            <a v-else-if=column.absoluteLink :href="itemLink(item, column)" class="text-secondary text-xs mb-0" target="blank">{{ itemValue(item, column) }}</a>
            <div v-else-if=column.list>
              <p v-for="elem in item[column.key]" :key="elem" class="text-secondary text-xs mb-0">
                {{ this.$t('field.' + elem.system.toLowerCase()) + " : " + elem.value }}
              </p>
            </div>
            <span v-else-if=column.flag class="text-secondary text-xs mb-0" :id="`label-${item.id}`">{{ itemValue(item, column) }}</span>
            <span v-else class="text-secondary text-xs mb-0">{{ itemValue(item, column) }}</span>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: 'TableBase',
    props: {
      modelValue: {
        type: Array,
        default: () => []
      },
      columns: Array,
      data: Array,
      apiLink: String,
      flag: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update:modelValue'],
    data() {
      return {
        isCheckAll: false,
      }
    },
    computed: {
      list: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },
    watch: {
      flag() {
        this.list.forEach(element => {
          let refName = 'label-'+element
          let e = this.$el.querySelector('#'+refName)
          e.innerHTML = this.$t('value.yes')
        })
      }
    },
    methods: {
      hasValue(item, column) {
        return item[column.label.toLowerCase()] !== 'undefined'
      },
      itemLink(item, column) {
        try {
          if(column.linkFormatter) {
            return this.apiLink + column.linkFormatter(item)
          }
          if(item['endpoint'] != null) {
            return item['endpoint']
          }
          if(item['id'] != null) {
            return this.apiLink + item['id']
          }
        } catch(error) {
          console.error(error)
        }
        return '#'
      },
      itemValue(item, column) {
        try {
          switch(column.key) {
            case "practitioner":
              return column.formatter(item)
            case "active":
            case "address":
            case "providedBy":
            case "contacts":
            case "roles":
              return column.formatter(item[column.key])
            default:
              return item[column.key]
          }
        } catch(error) {
          console.error(error)
        }
        return ''
      },
      switchAll() {
        this.isCheckAll = !this.isCheckAll
        if(this.isCheckAll) {
          this.selectRows()
        } else {
          this.deselectRows()
        }
      },
      selectRows() {
        this.$refs.checkbox.forEach(element => {
          this.list.push(element.id)
        });
      },
      deselectRows() {
        this.list = []
        //this.list.splice(0, this.list.length)
      },
    }
  }
</script>