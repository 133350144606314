import parseJwt from '@/services/jwtparser'

class TokenService {

  getCredentials() {
    const credentials = JSON.parse(localStorage.getItem("credentials"))
    const token = credentials?.accessToken
    if (!token) {
        return null
    }
    const jwtPayload = parseJwt(token);
    if (jwtPayload.exp < Date.now()/1000) {
        // token expired
        this.removeCredentials();
        return null
    }
    return credentials
  }

  getUser() {
    const credentials = this.getCredentials()
    return credentials?.user
  }

  getLocalRefreshToken() {
    const credentials = JSON.parse(localStorage.getItem("credentials"))
    return credentials?.refreshToken
  }

  getLocalAccessToken() {
    const credentials = JSON.parse(localStorage.getItem("credentials"))
    return credentials?.accessToken
  }

  getLocalUserId() {
    const credentials = JSON.parse(localStorage.getItem("credentials"))
    return credentials?.user?.id
  }

  updateLocalAccessToken(accessToken) {
    const credentials = JSON.parse(localStorage.getItem("credentials"))
    credentials.accessToken = accessToken
    localStorage.setItem("credentials", JSON.stringify(credentials))
  }

  setCredentials(credentials) {
    localStorage.setItem("credentials", JSON.stringify(credentials))
  }

  removeCredentials() {
    localStorage.removeItem("credentials")
  }
}

export default new TokenService()