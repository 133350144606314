<template>
    <main class="main-content mt-0">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-5 col-md-12 d-flex flex-column ms-xl-6">
                            <alert-content/>
                            <auth-content></auth-content>
                        </div>
                        <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden">
                                <span class="d-flex justify-content-center">
                                    <img src="/img/logo-cpts.webp" alt="logo cpts" class="login-background" />
                                </span>
                                <h4 class="mt-5 text-white font-weight-bolder position-relative">{{  $t('layout.auth.title') }}</h4>
                                <p class="text-white position-relative">{{  $t('layout.auth.subtitle') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import AuthContent from './AuthContent.vue'
import AlertContent from '@/layouts/common/Alert.vue'

export default {
  components: {
      AuthContent,
      AlertContent
  }
}
</script>
