import auth from "./auth"
import TokenService from "./token.service"

class AuthService {

    async login(user) {
        const response = await auth.post('/auth/signin', user)
        if (response.data.accessToken && response.data.refreshToken) {
            // store user details and jwt in local storage to keep user logged in between page refreshes
            TokenService.setCredentials({
                user: response.data.user,
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken
            })
        }
        return response.data.user
    }

    async refreshToken() {
        const response = await auth.post("/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
        })
        const { accessToken } = response.data // refreshToken remains the same, no need to update it
        TokenService.updateLocalAccessToken(accessToken)
    }

    async logout() {
        let id = TokenService.getLocalUserId()
        TokenService.removeCredentials()
        if(id !== 'undefined') {
            auth.post('auth/signout/' + id)
        }
    }

    async pwdChangeRequest(email) {
        return auth.post('/auth/credentials/reset', { email: email })
    }

    async reset(token, credentials) {
        return auth.put('/auth/credentials?token=' + token, credentials)
    }

}

export default new AuthService()