<template>
    <div class="card card-plain">
        <div class="card-header pb-0 text-start bg-gray-100">
            <h4 class="font-weight-bolder">{{  $t('form.reset.title') }}</h4>
            <!--p class="text-sm mb-0">{{  $t('form.password.recovery') }}</p-->
            <p class="text-sm mt-1 mb-0">{{  $t('form.password.recovery-subtitle') }}</p>
        </div>
        <div class="card-body">
            <form role="form">
                <!--div class="mb-3 hide">
                    <label for="Token" class="control-label">{{  $t('field.token') }}</label>
                    <input type="text"
                            id="Token"
                            v-bind:placeholder="$t('placeholder.token')"
                            v-model="username"
                            class="form-control" />
                </div-->
                <div class="mb-3">
                    <label for="Password" class="control-label">{{  $t('field.password') }}</label>
                    <input type="password"
                            id="Password"
                            v-bind:placeholder="$t('placeholder.password')"
                            v-model="password"
                            class="form-control" />
                </div>
                <div class="mb-3">
                    <label for="ConfirmPassword" class="control-label">{{  $t('field.confirmPassword') }}</label>
                    <input type="password"
                            id="ConfirmPassword"
                            v-bind:placeholder="$t('placeholder.confirmPassword')"
                            v-model="confirmPassword"
                            class="form-control" />
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-lg btn-primary w-100 mb-0" @click.prevent="reset">
                        {{  $t('button.modify-password') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="card-footer text-center pt-0 px-lg-2 px-1">
            <p class="mb-4 text-sm mx-auto">
                {{  $t('form.password.account-exist') }}
                <a href="https://welcome.cpts-paris15.fr">{{  $t('form.password.connect') }}</a>
            </p>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import authService from '@/services/auth.service'
import { useAlertStore } from '@/stores/alert.store'

export default {
    data() {
        return {
            //username: '',
            password: '',
            confirmPassword: ''
        }
    },
    setup() {
        const route = useRoute()
        const token = route.query.token
        return { token }
    },
    methods: {
        async reset () {
            const credentials = {
                token: this.token,
                password: this.password,
                confirmPassword: this.confirmPassword
            }
            await authService.reset(this.token, credentials) // TODO: use store here
                .then(() => {
                    this.$router.push("/login")
                })
                .catch(error => {
                    const alertStore = useAlertStore()
                    if(error.response?.data) {
                        console.error(error.response.data)
                    }
                    alertStore.error(error)
                })
        }
    }
}
</script>