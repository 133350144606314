<template>
  <KeepAlive>
    <side-bar :title="this.$t('global.title')" :sidebarLinks="links"/>
  </KeepAlive>
</template>

<script>
  export default {
    name: 'SidebarContent',
    data() {
      return {
        loggedInUser: null,
        links: [
          { subtitle: this.$t('sidebar.organizations') },
          { path: { name: 'HospitalsList', query: { category_group: 'HOSPITAL' } }, icon: ['fas', 'square-h'], name:  this.$t('sidebar.organizations-hospital') },
          { path: { name: 'HealthcentersList', query: { category_group: 'HEALTHCENTER' } }, icon: ['fas', 'house-medical-flag'], name: this.$t('sidebar.organizations-healthcenter') },
          { path: { name: 'PsychiatryList', query: { category_group: 'PSYCHIATRY' } }, icon: ['fas', 'house-chimney-medical'], name: this.$t('sidebar.organizations-psychiatry') },
          { path: { name: 'OthercentersList', query: { category_group: 'OTHER' } }, icon: ['fas', 'hospital'], name: this.$t('sidebar.organizations-others') },
          { subtitle: this.$t('sidebar.healthcareService') },
          { path: { name: 'HealthcareServicesList' }, icon: ['fas', 'hospital-user'], name: this.$t('sidebar.services') },
          { path: { name: 'PractitionersList' }, icon: ['fas', 'stethoscope'], name: this.$t('sidebar.practitioners') },
          { path: { name: 'PrescriptionList' }, icon: ['fas', 'paste'], name: this.$t('sidebar.prescription') },
          { render: "SUPERADMIN", subtitle: this.$t('sidebar.settings') },
          { render: "SUPERADMIN", path: { name: 'UsersList' }, icon: ['fas', 'users-gear'], name: this.$t('sidebar.users') },
          { render: "SUPERADMIN", path: { name: 'OrganizationGroupList' }, icon: ['fas', 'table'], name: this.$t('sidebar.organizationGroups') },
          { render: "SUPERADMIN", path: { name: 'OrganizationCategoryList' }, icon: ['fas', 'table'], name: this.$t('sidebar.organizationCategories') },
          { render: "SUPERADMIN", path: { name: 'PractitionerCategoryList' }, icon: ['fas', 'table-columns'], name: this.$t('sidebar.practitionerCategories') },
          { render: "SUPERADMIN", path: { name: 'SpecializationList' }, icon: ['fas', 'table-list'], name: this.$t('sidebar.specializations') },
        ],
      }
    },
  }
</script>