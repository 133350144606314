import api from "./api"

class SpecializationService {

    async getAll() {
        return api.get('/api/specializations')
    }

    async save(specialization) {
        return api.post('/api/specializations', specialization)
    }

    async update(id, specialization) {
        return api.put('/api/specializations/' + id, specialization)
    }

    async removeById(id) {
        return api.delete('/api/specializations/' + id)
    }

}

export default new SpecializationService()