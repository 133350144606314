<template>
    <main class="main-content mt-0">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 d-flex flex-column mx-lg-0 mx-auto">
                            <error-content></error-content>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import ErrorContent from './ErrorContent.vue'

export default {
    components: {
        ErrorContent
    }
}
</script>
