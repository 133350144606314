<template>
  <loading v-model:active="loading" :can-cancel="false" :is-full-page="true" />
  <div class="row">
    <div class="col-lg-8">
      <div class="card mb-4">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="text-uppercase text-sm">{{  $t('section.information') }}</p>
          </div>
        </div>
        <div class="card-body py-2">
          <form>
            <div v-if="practitioner.id" class="row" style="display: none;">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="Id" class="control-label">{{  $t('field.id') }}</label>
                  <input type="text"
                      id="Id"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="practitioner.id"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="CodeADELI" class="control-label">{{  $t('field.codeADELI') }}</label>
                  <input type="text"
                      id="CodeADELI"
                      v-bind:placeholder="$t('placeholder.codeADELI')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="practitioner.codeADELI"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="codeRPPS" class="control-label">{{  $t('field.codeRPPS') }}</label>
                  <input type="text"
                      id="codeRPPS"
                      v-bind:placeholder="$t('placeholder.codeRPPS')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="practitioner.codeRPPS"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstName" class="control-label">{{  $t('field.firstName') }}</label>
                  <input type="text"
                      id="firstName"
                      v-bind:placeholder="$t('placeholder.firstName')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model.trim="practitioner.firstName"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lastName" class="control-label">{{  $t('field.lastName') }}</label>
                  <input type="text"
                      id="lastName"
                      v-bind:placeholder="$t('placeholder.lastName')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model.trim="practitioner.lastName"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="Exercice" class="control-label">{{  $t('field.exercice') }}</label>
                  <input type="text"
                      id="Exercice"
                      v-bind:placeholder="$t('placeholder.exercice')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="practitioner.exercice"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select-enum
                      v-bind:attribut="attrAgreement"
                      v-bind:label="$t('field.agreement')"
                      v-bind:placeholder="$t('placeholder.agreement')"
                      v-bind:enumeration="agreementTypes"
                      v-model="practitioner.agreement"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <select-store
                      v-bind:placeholder="$t('placeholder.practitionerCategory')"
                      v-bind:label="$t('field.category')"
                      v-model="practitioner.category" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="specializationsTags" class="control-label">{{  $t('field.specializations') }}</label>
                  <tags-input element-id="specializationsTags"
                      ref="specializationsTagsInput"
                      inputId="specializationsTags"
                      :placeholder="$t('placeholder.specializationsTags')"
                      :existing-tags="specializations"
                      id-field="code"
                      text-field="name"
                      :value="specializationsSelectedNodes"
                      :typeahead-activation-threshold="3"
                      :typeahead="true"
                      :typeahead-hide-discard="true"
                      :hide-input-on-limit="true"
                      :add-tags-on-comma="true"
                      @tag-added="addSpecialization"
                      @tag-removed="removeSpecialization"
                      />
                </div>
              </div>
            </div>

            <hr class="horizontal dark">
            <p class="text-uppercase text-sm">{{  $t('section.policy') }}</p>

            <div class="form-group row">
              <label class="control-label col-8">{{  $t('placeholder.acceptNewPatients') }}</label>
              <div class="col">
                <div class="form-check form-switch text-end">
                  <label class="form-check-label" for="acceptNewPatients">{{ $t('value.no') }}</label>
                  <input type="checkbox"
                    id="acceptNewPatients"
                    class="form-check-input"
                    style="margin-left: 0.5rem;"
                    v-model="practitioner.acceptNewPatients"
                  />
                  <label class="form-check-label" for="acceptNewPatients">{{ $t('value.yes') }}</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label col-8">{{  $t('placeholder.rp') }}</label>
              <div class="col">
                <div class="form-check form-switch text-end">
                  <label class="form-check-label" for="rp">{{ $t('value.no') }}</label>
                  <input type="checkbox"
                    id="rp"
                    class="form-check-input"
                    style="margin-left: 0.5rem;"
                    v-model="practitioner.rp"
                  />
                  <label class="form-check-label" for="rp">{{ $t('value.yes') }}</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label col-8">{{  $t('placeholder.deliverHomeCare') }}</label>
              <div class="col">
                <div class="form-check form-switch text-end">
                  <label class="form-check-label" for="deliverHomeCare">{{ $t('value.no') }}</label>
                  <input type="checkbox"
                    id="deliverHomeCare"
                    class="form-check-input"
                    style="margin-left: 0.5rem;"
                    v-model="practitioner.deliverHomeCare"
                  />
                  <label class="form-check-label" for="deliverHomeCare">{{ $t('value.yes') }}</label>
                </div>
              </div>
            </div>

            <div v-if="isAdmin" class="row">
              <div class="col-md-12 d-flex justify-content-center">
                <button v-if="practitioner.id" type="submit" class="btn btn-info btn-fill float-right m-2" @click.prevent="update">
                  {{  $t('button.update') }}
                </button>
                <button v-else type="submit" class="btn btn-info btn-fill float-right m-2" @click.prevent="save">
                  {{  $t('button.add') }}
                </button>
                <button v-if="practitioner.id" type="submit" class="btn btn-outline-danger btn-fill float-right m-2" @click.prevent="remove">
                  {{  $t('button.remove') }}
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <CardAddress
          :id="practitioner.id"
          @update:address="onUpdateAddress"
          v-model="practitioner.address"
      />
      <CardTelecom
          :id="practitioner.id"
          @add:telecom="onAddTelecom"
          @update:telecom="onUpdateTelecom"
          @remove:telecom="onRemoveTelecom"
          v-model="practitioner.telecom"
      />
      <CardManagingEntity
          :id="practitioner.id"
          @add:managingEntity="onAddManagingEntity"
          @update:managingEntity="onUpdateManagingEntity"
          @remove:managingEntity="onRemoveManagingEntity"
          v-model="practitioner.managingEntities"
      />
      <CardMessage v-if="isAdmin && feature_sendSms"
        :numbers="numbers"
        v-bind:placeholder="$t('placeholder.numbers')"
      />
    </div>
  </div>
</template>

<script>
  import { provide } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { storeToRefs, mapState } from 'pinia'
  import { i18n } from '@/helpers/i18n'
  import { usePractitionerStore } from '@/stores/practitioner.store'
  import { usePractitionerCategoryStore } from '@/stores/practitionerCategory.store'
  import { useSpecializationStore } from '@/stores/specialization.store'
  import { useAuthStore } from '@/stores/auth.store'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'
  import SelectEnum from '@/components/Select/SelectEnum.vue'
  import SelectStore from '@/components/Select/SelectStore.vue'
  import TagsInput from '@/components/Tags/TagsInput.vue'
  import CardAddress from '@/components/Cards/CardAddress.vue'
  import CardTelecom from '@/components/Cards/CardTelecom.vue'
  import CardManagingEntity from '@/components/Cards/CardManagingEntity.vue'
  import CardMessage from '@/components/Cards/CardMessage.vue'

  import { useFlag } from '@unleash/proxy-client-vue'

  export default {
    components: {
      Loading,
      SelectEnum,
      SelectStore,
      TagsInput,
      CardAddress,
      CardTelecom,
      CardManagingEntity,
      CardMessage
    },
    data () {
      return {
        specializationsSelectedNodes: [],
        attrAgreement: 'Agreement',
        agreementTypes: [
          'Secteur 1 - Conventionné',
          'Secteur 2 - Conventionné à honoraires libres',
          'Secteur 3 - Non conventionné'
        ],
      }
    },
    setup() {
      const route = useRoute()
      const router = useRouter()

      provide('select-store', usePractitionerCategoryStore())

      const specializationStore = useSpecializationStore()
      const { specializations } = storeToRefs(specializationStore)
      specializationStore.fetchList()

      const store = usePractitionerStore()
      const { practitioner, loading, getPractitionerMobileNumbers } = storeToRefs(store)
      const { fetchById, reset } = store

      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      const id = route.params.id
      id !== '0' ? fetchById(id) : reset()

      const save = () => {
        store.save(practitioner.value)
        router.push('/practitioners')
      }
      const update = () => {
        store.update(id, practitioner.value)
      }
      const remove = () => {
        let resp = confirm(i18n.t('modal.remove.practitioner'))
        if(resp) {
          store.remove(id)
          router.push('/practitioners?refresh=true')
        }
      }
      const updateAddress = (id, address) => {
        store.updateAddress(id, address)
      }
      const updateTelecom = (id, telecom) => {
        store.updateTelecom(id, telecom)
      }
      const addManagingEntity = (id, managingEntity) => {
        store.addManagingEntity(id, managingEntity)
      }
      const updateManagingEntity = (id, managingEntity) => {
        store.updateManagingEntity(id, managingEntity)
      }
      const removeManagingEntity = (id, managingEntity) => {
        store.removeManagingEntity(id, managingEntity)
      }

      const feature_sendSms = useFlag('send_sms')
      console.debug(feature_sendSms.value)

      return {
        id,
        practitioner, getPractitionerMobileNumbers,
        specializations,
        loading,
        save, update, remove,
        updateAddress,
        updateTelecom,
        addManagingEntity, updateManagingEntity, removeManagingEntity,
        isAdmin, feature_sendSms
      }
    },
    computed: {
      managingEntitiesNames() {
        return this.managingEntitiesSelectedNodes
      },
      specializationsNames() {
        return this.specializationsSelectedNodes.map((item) => {
          return item.name
        })
      },
      ...mapState(usePractitionerStore, {
        numbers: 'getPractitionerMobileNumbers'
      })
    },
    watch: {
      practitioner() {
        if(this.practitioner.managingEntities) {
          this.managingEntitiesSelectedNodes = []
          this.practitioner.managingEntities.forEach((item) => {
            let node = { url: item.url , name: item.name }
            this.managingEntitiesSelectedNodes.push(node)
          })
        }
        if(this.practitioner.specializations) {
          this.specializationsSelectedNodes = []
          let mytags = this.practitioner.specializations.split(', ')
          mytags.forEach((item, index) => {
            let node = { code: index.toString(), name: item }
            this.specializationsSelectedNodes.push(node)
          })
        }
        if(!this.practitioner.telecom) {
          this.practitioner.telecom = []
        }
      },
    },
    methods: {
      addSpecialization(tag) {
        let isMatch = this.specializationsSelectedNodes.find(item => {
          return item.name === tag.name
        })
        if(!isMatch) {
          this.specializationsSelectedNodes.push(tag);
          this.practitioner.specializations = this.specializationsNames.join(', ')
        }
      },
      removeSpecialization(tag) {
        let index = this.specializationsSelectedNodes.indexOf(tag)
        this.specializationsSelectedNodes.splice(index, 1);
        this.practitioner.specializations = this.specializationsNames.join(', ')
      },

      // handling address

      onUpdateAddress(address) {
        console.debug(address)
        this.updateAddress(this.id, address)
      },

      // handling telecom list

      onAddTelecom(telecom) {
        console.debug(telecom)
        this.practitioner.telecom.push(telecom)
        this.updateTelecom(this.id, this.practitioner.telecom)
      },
      onUpdateTelecom(telecom, oldValue) {
        console.debug(telecom)
        this.practitioner.telecom = this.practitioner.telecom.map(item => {
          if(item === oldValue) {
            return telecom
          }
          return item
        })
        this.updateTelecom(this.id, this.practitioner.telecom)
      },
      onRemoveTelecom(telecom) {
        console.debug(telecom)
        this.practitioner.telecom = this.practitioner.telecom.filter(item => item !== telecom)
        this.updateTelecom(this.id, this.practitioner.telecom)
      },

      // handling managing entity single entry

      onAddManagingEntity(managingEntity) {
        console.debug(managingEntity)
        this.addManagingEntity(this.id, managingEntity)
      },
      onUpdateManagingEntity(managingEntity) {
        console.debug(managingEntity)
        this.updateManagingEntity(this.id, managingEntity)
      },
      onRemoveManagingEntity(managingEntity) {
        console.debug(managingEntity)
        this.removeManagingEntity(this.id, managingEntity)
      }
    },
    /*beforeRouteLeave(to, from) {
      console.log(to, from)
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (!answer) return false
    }*/
  }
</script>
