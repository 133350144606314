<template>
    <div class="modal fade" ref="contactModal" id="contactModal" aria-hidden="true" aria-labelledby="contactModalLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="card card-plain">
                        <div class="card-header pb-0 text-left">
                            <h3 class="font-weight-bolder text-primary text-gradient" id="contactModalLabel">{{  $t('modal.contact.title') }}</h3>
                            <p class="mb-0">{{  $t('modal.contact.subtitle') }}</p>
                        </div>
                        <div class="card-body pb-3">
                            <form role="form text-left">
                                <label for="contactPurpose">{{  $t('field.purpose') }}</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control form-control-sm"
                                            v-bind:placeholder="$t('placeholder.purpose')"
                                            id="contactPurpose"
                                            aria-label="purpose"
                                            aria-describedby="addon-right addon-left"
                                            v-model.trim="contact.purpose"
                                        />
                                </div>
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col">
                                            <label for="contactLastName">{{  $t('field.lastName') }}</label>
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control form-control-sm"
                                                    v-bind:placeholder="$t('placeholder.lastName')"
                                                    id="contactLastName"
                                                    aria-label="lastName"
                                                    aria-describedby="addon-right addon-left"
                                                    v-model.trim="contact.lastName"
                                                />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label for="contactFirstName">{{  $t('field.firstName') }}</label>
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control form-control-sm"
                                                    v-bind:placeholder="$t('placeholder.firstName')"
                                                    id="contactFirstName"
                                                    aria-label="firstName"
                                                    aria-describedby="addon-right addon-left"
                                                    v-model.trim="contact.firstName"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 d-flex align-items-center">
                                    <p class="form-label">{{  $t('section.telecom') }}</p>
                                </div>
                                <div class="border-radius-lg mb-3 p-3 telecom-border">
                                    <label for="contactPhone">{{  $t('field.phone') }}</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control form-control-sm"
                                            id="contactPhone"
                                            v-bind:placeholder="$t('placeholder.phone')"
                                            aria-label="phone"
                                            aria-describedby="addon-right addon-left"
                                            v-model="phone"
                                        />
                                    </div>
                                    <label for="contactEmail">{{  $t('field.email') }}</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control form-control-sm"
                                            v-bind:placeholder="$t('placeholder.email')"
                                            id="contactEmail"
                                            aria-label="email"
                                            aria-describedby="addon-right addon-left"
                                            v-model.trim="email"
                                        />
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">{{  $t('button.close') }}</button>
                                    <button v-if="upsert" type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="addContact">{{  $t('button.add') }}</button>
                                    <button v-else type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="updateContact">{{  $t('button.update') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
    name: 'ModalContact',
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    emits: ['add:contact', 'update:contact'],
    computed: {
        contact: {
            get() {
                return this.modelValue
            }
        },
        phone: {
            get() {
                return this.getTelecomValue('PHONE')
            },
            set(newValue) {
                this.setTelecomValue('PHONE', newValue)
            }
        },
        email: {
            get() {
                return this.getTelecomValue('EMAIL')
            },
            set(newValue) {
                this.setTelecomValue('EMAIL', newValue)
            }
        },
        upsert: {
            get() {
                return !this.modelValue.id
            } 
        }
    },
    methods: {
        show() {
            let modal = new Modal(this.$refs.contactModal)
            modal.show()
        },
        addContact() {
            this.$emit('add:contact', this.contact)
        },
        updateContact() {
            this.$emit('update:contact', this.contact)
        },
        getTelecomValue(system) {
            let reslt = this.contact.telecom.find((t) => t.system === system)
            return reslt ? reslt.value : ''
        },
        setTelecomValue(system, newValue) {
            let node = {
                system: system,
                value: newValue,
                use: 'PUBLIC'
            }
            const index = this.contact.telecom.findIndex(t => t.system === system)
            if(index > -1) {
                this.contact.telecom.splice(index, 1, node)
            } else {
                this.contact.telecom.push(node)
            }
        }
    }
}
</script>