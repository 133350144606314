import api from "./api"

class SmsService {

  async send(params) {
    return api.post('/api/sms', params)
  }

}

export default new SmsService()