import axios from "axios"

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  cache: {
    maxAge: 30 * 60 * 1000, // Cache for 30 minutes
  },
  withCredentials: false
})

export default instance