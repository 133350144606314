import { defineStore } from 'pinia'

export const useAlertStore = defineStore('alert', {
    state: () => ({
        alerts: []
    }),
    actions: {
        clear() {
            this.alerts = []
        },
        removeAt(index) {
            console.debug("removing at index", index)
            this.alerts.splice(index, 1)
        },
        success(message) {
            this.alerts.push({ message, type: 'alert alert-success'})
        },
        info(message) {
            this.alerts.push({ message, type: 'alert alert-info'})
        },
        error(message) {
            this.alerts.push({ message, type: 'alert alert-danger'})
        }
    }
})