<template>
    <loading v-model:active="loading" :can-cancel="false" :is-full-page="true" />
    <div class="row">
        <div class="col-12">
            <div class="card mb-4">
                <div class="card-header pb-0 div-center form-group">
                    <img src="/img/logo-cpts.webp" width="190" height="190" alt="">
                </div>
                <div class="card-body px-0 pt-0 pb-2">
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <div class="select-container mb-3">
                                    <input type="text"
                                        id="search"
                                        class="form-control border-primary mb-0 mt-2"
                                        :class="{ 'border-danger': isNoValidToken }"
                                        :placeholder="$t('placeholder.search')"
                                        v-model="filterText"
                                        @keyup.enter="noFilter"
                                    />
                                    <ul v-if="showOptions" class="options-list">
                                        <li v-for="(token, index) in tiers" :key="index" @click="selectTiers(token)">
                                            {{ token.name }}
                                        </li>
                                    </ul>
                                </div>
                                <div v-show="isNoValidToken"><span class="fs-8 text-danger">{{  $t('form.search.invalid-token') }}</span></div>
                                <a class="ms-auto" href="javascript:" @click="isOpen = !isOpen">
                                    <p class="text-end fs-8 fw-lighter mb-0">{{  $t('form.search.subtitle') }}
                                        <font-awesome-icon :icon="['fas', 'arrow-down']" size="xs" v-show="!isOpen" />
                                        <font-awesome-icon :icon="['fas', 'arrow-up']" size="xs" v-show="isOpen" />
                                    </p>
                                </a>
                            </div>
                            <card-filtered-search v-show="isOpen"
                                    :underlineCategory="isNoValidCategory"
                                    :underlineLocation="isNoValidLocation"
                                    @set-collection-data="updateCollection"
                                    @set-category-data="updateCategory"
                                    @set-address-data="updateAddress"
                                    @set-location-data="updateLocation"
                                    @set-distance-data="updateDistance"
                                >
                            </card-filtered-search>
                            <div class="col-md-12 text-center">
                                <button type="submit" class="btn btn-primary btn-sm sm-auto" @click.prevent="search">
                                    {{  $t('button.search') }}
                                </button>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-show="showResults">
        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-header pb-0">
                    <h6>{{  $t('form.search.results') }}</h6>
                </div>
                <div class="card-body pt-0">
                    <SearchResults
                            :data="tiers"
                            :columns="table.columns"
                            :apiLink="apiLink"
                    />
                </div>
                <div class="card-footer">
                    <base-pagination :totalPages="totalPages"
                            :currentPage="page"
                            :maxVisibleButtons="5"
                            @page-changed="onPageChanged" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useTiersStore } from '@/stores/tiers.store'
import { useAlertStore } from '@/stores/alert.store'

import CardFilteredSearch from '@/components/Cards/CardFilteredSearch.vue'
//import SelectOption from '@/components/Select/SelectOption.vue'
import SearchResults from '@/components/List/SearchResults.vue'
import BasePagination from '@/components/Pagination/BasePagination.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

export default {
    components: {
        CardFilteredSearch,
        //SelectOption,
        SearchResults,
        BasePagination,
        Loading
    },
    name: 'SearchPage',
    setup() {
        const store = useTiersStore()
        const { tiers/*, autocompleteList*/, totalPages, loading/*, showResults*/ } = storeToRefs(store)
        const { fetchAll } = store

        return { tiers/*, autocompleteList*/, totalPages, loading/*, showResults*/, fetchAll }
    },
    data() {
        return {
            page: 1,
            sizeValue: '10',
            size: 10,
            pageSizes: [ 10, 20, 50 ],

            filterText: '',
            filteredTiers: [],
            collection: '',
            category: '',
            address: '',
            location: null,
            distance: 100,

            isOpen: false,
            showResults: false,
            showOptions: false,

            isNoValidToken: false,
            isNoValidCategory: false,
            isNoValidLocation: false,

            table: {
                columns: [
                    {
                        key: 'name',
                        label: this.$t('field.name'),
                        link: true,
                        title: true
                    },
                    {
                        key: 'category',
                        label: this.$t('field.category'),
                    },
                    {
                        key: 'address',
                        label: this.$t('field.address'),
                        formatter: value => {
                            if(value) {
                                return value.streetNumber + ' ' + value.streetName + ', ' + value.zipCode + ' ' + value.city
                            }
                            return ''
                        }
                    },
                    {
                        key: 'telecom',
                        label: this.$t('field.telecom'),
                        list: true
                    },
                ],
                apiLink: 'endpoint'
            },
            apiLink: null,
        }
    },
    created() {
        if(this.loggedIn) {
            this.$router.push('/')
        }
    },
    methods: {
        isFormValidate() {
            this.isNoValidToken = false
            this.isNoValidCategory = false
            this.isNoValidLocation = false
            if(this.filterText) {
                return true
            }
            if(!this.filterText && !this.isOpen) {
                this.isNoValidToken = true
                return false
            }
            // from here, no filterText, but filtered search is open
            if(!this.collection && !this.category && !this.address) {
                // consider that filtered search is closed
                this.isNoValidToken = true
                return false
            }
            // focus now on filtered search that is not complete
            if(this.collection && !this.category && !this.address) {
                this.isNoValidCategory = true
                return false
            }
            return true
        },
        getRequestParams() {
            let params = {}
            if(this.filterText) {
                params["q"] = this.filterText
            }
            if(this.collection) {
                params["collection"] = this.collection
            }
            if(this.category) {
                params["category"] = this.category
            }
            if(this.page) {
                params["page"] = this.page - 1
            }
            if(this.address && this.location) {
                params["x"] = this.location.lat()
                params["y"] = this.location.lng()
                params["distance"] = this.distance
            }
            if(this.size) {
                params["size"] = this.size
            }
            return params
        },
        async search() {
            if(this.isFormValidate()) {
                const params = this.getRequestParams()
                await this.fetchAll(params)
                this.showOptions = true
            }
        },
        selectTiers(token) {
            console.log(token)
            console.log(token.endpoint)
            //this.filterText = token.name
            this.showOptions = false
            this.showResults = true
            this.$router.push(token.endpoint)
        },
        onPageChanged(index) {
            console.debug("page index = " + index)
            this.page = index
            this.search()
        },
        updateCollection (collection) {
            this.collection = collection
        },
        updateCategory (category) {
            this.category = category
        },
        updateAddress (address) {
            this.address = address
        },
        updateLocation (location) {
            this.location = location
        },
        updateDistance (distance) {
            this.distance = distance
        },
        noFilter() {
            this.showOptions = false
            this.showResults = this.tiers.length > 0
        },
    },
    watch: {
        filterText: function(newValue) {
            if(newValue?.length > 2) {
                this.search()
                return
            }
            this.filteredTiers = []
            this.showOptions = false
        },
        /*tiers: function(newValue) {
            this.filteredTiers = newValue
            this.showOptions = this.filteredTiers.length > 0
        },*/
        isNoValidToken(newValue) {
            if(newValue) {
                const alertStore = useAlertStore()
                alertStore.error(this.$t('form.search.validate'))
            }
        }
    }
}
</script>