<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" aria-label="navbar" data-scroll="false">
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <breadcrumb-content />
        <h6 class="font-weight-bolder text-white mb-0">{{  $t('topmenu.title') }}</h6>
      </nav>
      <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          <!--div class="input-group">
            <span class="input-group-text text-body">
              <font-awesome-icon :icon="['fa', 'fa-search']" />
            </span>
            <input type="text" class="form-control" :placeholder="$t('placeholder.navbar-search')">
          </div-->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex">
            <a href="/search" class="nav-link text-white font-weight-bold me-2">
              <font-awesome-icon :icon="['fa', 'fa-magnifying-glass']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  $t('topmenu.search') }}
              </span>
            </a>
          </li>
          <li class="nav-item d-flex">
            <a v-if="feature_securedMessaging" href="/secured-messaging" class="nav-link text-white font-weight-bold me-2">
              <font-awesome-icon :icon="['fa', 'fa-envelope']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  $t('topmenu.secured-messaging') }}
              </span>
            </a>
            <a v-else href="mailto:contact@cpts-paris15.com" class="nav-link text-white font-weight-bold me-2">
              <font-awesome-icon :icon="['fa', 'fa-envelope']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  $t('topmenu.contact-us') }}
              </span>
            </a>
          </li>
          <li class="nav-item d-flex">
            <a href="/map" class="nav-link text-white font-weight-bold me-2">
              <font-awesome-icon :icon="['fa', 'fa-location-dot']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  $t('topmenu.map') }}
              </span>
            </a>
          </li>
          <li v-if="feature_chat" class="nav-item d-flex">
            <a href="/chat" class="nav-link text-white font-weight-bold me-2">
              <font-awesome-icon :icon="['fa', 'fa-comments']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  $t('topmenu.chat') }}
              </span>
            </a>
          </li>
          <!--li v-if="isSuperAdmin" class="nav-item d-flex">
            <a href="/assistant" class="nav-link text-white font-weight-bold me-2">
              <font-awesome-icon :icon="['fa', 'fa-microchip']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  $t('topmenu.assistant') }}
              </span>
            </a>
          </li-->
         <li class="nav-item dropdown pe-2 d-flex align-items-center">
            <a href="#" class="btn bg-gradient-primary nav-link text-white font-weight-bold p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
              <font-awesome-icon :icon="['fa', 'fa-user']" class="me-sm-2" />
              <span class="d-sm-inline d-none">
                {{  formatUsername(user?.username) }}
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuLink">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="/user">{{  $t('topmenu.my-account') }}</a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="#" @click="logout">{{  $t('topmenu.logout') }}</a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a @click="toggleSidebar" class="nav-link text-white p-0" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  import { storeToRefs } from 'pinia'
  import { useAuthStore } from '@/stores/auth.store'

  import BreadcrumbContent from '@/components/Breadcrumb/Breadcrumb.vue'

  import { useFlag } from '@unleash/proxy-client-vue'

  export default {
    components: {
      BreadcrumbContent,
    },
    setup () {
      const authStore = useAuthStore()
      const { user/*, isAdmin, isSuperAdmin*/ } = storeToRefs(authStore)

      const feature_securedMessaging = useFlag('secured-messaging')
      const feature_chat = useFlag('chat')

      return { user, /*isAdmin, isSuperAdmin,*/ feature_securedMessaging, feature_chat }
    },
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
    },
    methods: {
      formatUsername(name) {
        if(name) {
          if(name.length < 16) {
            return name
          }
          return name.slice(0, 13) + "..."
        }
        return this.$t('topmenu.user')
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      logout() {
        const authStore = useAuthStore()
        authStore.logout()
      }
    }
  }
</script>