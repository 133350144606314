import { defineStore } from 'pinia'
//import NotificationService from '@/services/notification.service'

export const useErrorStore = defineStore('error', {
    state: () => ({
        // properties...
    }),
    getters: {
        // computed properties...
    },
    actions: {
        notify(payload) {
            console.error("to be implemented - in error.store.js notify...", payload)
            //NotificationService.save(payload)
        }
    }
})