<template>
    <div class="min-height-300 bg-primary position-absolute w-100"></div>

    <sidebar-content/>

    <main class="main-content position-relative border-radius-lg">

      <navbar-content/>

      <div class="container-fluid py-4">
        <alert-content/>
        <main-content/>
        <footer-content/>
      </div>

    </main>

    <!--fixed-plugin tbd-->
</template>

<script>
import SidebarContent from './SidebarContent.vue'
import NavbarContent from './NavBarContent.vue'
import MainContent from './MainContent.vue'
import FooterContent from './FooterContent.vue'
import AlertContent from '@/layouts/common/Alert.vue'

export default {
  components: {
    SidebarContent,
    NavbarContent,
    MainContent,
    FooterContent,
    AlertContent,
  },
}
</script>
