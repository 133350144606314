import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
    // original regex : A-Za-z0-9-_ replaced by \w
    const locales = require.context('./locales', true, /[\w,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        // original regex : [A-Za-z0-9-_] replaced by \w
        const matched = key.match(/(\w+)\./i)
        if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)//.default
        }
    })
    return messages
}

const instance = createI18n({
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE || "fr",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
    //availableLocales: ["fr", "en"],
    messages: loadLocaleMessages()/*["fr", "en"]*/
})

export default instance

export const i18n = instance.global