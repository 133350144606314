import api from "./api";

class OrganizationService {

    async getAll(params) {
        return api.get('/api/organizations', {
            params: params
        })
    }

    async getById(id) {
        return api.get('/api/organizations/' + id)
    }

    async save(organization) {
        return api.post('/api/organizations', organization)
    }

    async update(id, organization) {
        return api.put('/api/organizations/' + id, organization)
    }

    // adressing address

    async updateAddress(id, address) {
        return api.put('/api/organizations/' + id + '/address', address)
    }

    // addressing contact through one single value

    async addContact(id, contact) {
        return api.patch('/api/organizations/' + id + '/contacts', contact)
    }

    async updateContact(id, contact) {
        return api.patch('/api/organizations/' + id + '/contacts', contact)
    }

    async removeContact(id, contact) {
        return api.delete('/api/organizations/' + id + '/contacts/' + contact.id)
    }

    // adressing telecom through value list

    async updateTelecom(id, telecom) {
        return api.put('/api/organizations/' + id + '/telecom', telecom)
    }

    async removeTelecom(id) {
        return api.delete('/api/organizations/' + id + '/telecom')
    }

    async removeById(id) {
        return api.delete('/api/organizations/' + id)
    }

}

export default new OrganizationService()