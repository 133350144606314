<template>
    <loading v-model:active="loading" :can-cancel="false" :is-full-page="true" />
    <div class="card card-plain">
        <div class="card-header pb-0 text-start bg-gray-100">
            <h3>{{  $t('global.title') }}</h3>
            <h4 class="font-weight-bolder">{{  $t('form.login.title') }}</h4>
        </div>
        <div class="card-body">
            <form>
                <div class="mb-3">
                    <input type="text" autocomplete="on"
                            id="Username"
                            v-bind:placeholder="$t('placeholder.username')"
                            v-model="user.username"
                            class="form-control" />
                </div>
                <div class="mb-3">
                    <input type="password"
                            id="Password"
                            v-bind:placeholder="$t('placeholder.password')"
                            autocomplete="on"
                            v-model="user.password"
                            class="form-control" />
                </div>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="rememberMe">
                    <label class="form-check-label" for="rememberMe">{{  $t('card.login.rememberMe') }}</label>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-lg btn-primary w-100 mt-4 mb-0" @click.prevent="login">
                        {{  $t('button.connect') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="card-footer text-center pt-0 px-lg-2 px-1">
            <p class="mb-4 text-sm mx-auto">
                <a href="/password" class="text-primary text-gradient font-weight-bold">{{  $t('form.login.forgot-password') }}</a>
            </p>
            <p class="mb-4 text-sm mx-auto">
                {{  $t('form.login.account-none') }}
                <a href="https://welcome.cpts-paris15.fr">{{  $t('form.login.account-open') }}</a>
            </p>
        </div>
    </div>
    <!--div v-if="isSafari">
        <p class="text-sm text-center mx-auto">{{  $t('form.login.isSafari') }}</p>
    </div-->
    <p class="text-sm text-center mx-auto">Version {{ version }}</p>
</template>

<script>
//import { ref, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth.store'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

export default {
    components: {
        Loading
    },
    data() {
        return {
            user: {
                username: '',
                password: ''
            },
            defaultUrl: '/',
            version: process.env.VUE_APP_VERSION,
            loading: false
        }
    },
    /*setup() {
        const isSafari = ref(false)

        const checkIfSafari = () => {
            const userAgent = navigator.userAgent
            isSafari.value = /^((?!chrome|android).)*safari/i.test(userAgent)
        }

        onMounted(() => {
            checkIfSafari()
        })

        return { isSafari }
    },*/
    created() {
        if(this.loggedIn) {
            this.$router.push(this.defaultUrl)
        }
    },
    /*mounted() {
        this.getUrlQueryParams()
    },*/
    methods: {
        async login () {
            const returnUrl = this.$route.query.returnUrl
            const authStore = useAuthStore()
            this.loading = true
            await authStore.login(this.user, returnUrl)
            this.loading = false
        },
        /*async getUrlQueryParams () {
            // The router wont be ready if its a page refresh. In that case the query wont be available.
            // The router will be ready if its navigation from another page in that case the query params will be available
            const router = useRouter()
            await router.isReady()
            this.returnUrl = router.query.returnUrl
            console.debug('get... : ' + this.returnUrl)
        }*/
    }
}
</script>