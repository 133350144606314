import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import SmsService from '@/services/sms.service'

export const useSmsStore = defineStore('sms', {
    state: () => ({
        loading: false,
    }),
    actions: {
        async send(phone, message) {
            const params = { phone: phone, message: message }
            this.loading = true
            await SmsService.send(params)
                .then(response => {
                    let resp = response.data
                    let message = i18n.t('message.sms.sent')
                    useAlertStore().success(message)
                    return resp
                })
                .catch(error => {
                    useAlertStore().error(error.message)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
})