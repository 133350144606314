import api from "./api"

class OrganizationCategoryService {

    async getAll() {
        return api.get('/api/organizationCategories')
    }

    async save(category) {
        return api.post('/api/organizationCategories', category)
    }

    async update(id, category) {
        return api.put('/api/organizationCategories/' + id, category)
    }

    async removeById(id) {
        return api.delete('/api/organizationCategories/' + id)
    }

}

export default new OrganizationCategoryService()