import api from "./api";

class TiersService {

    async getAll(params) {
        return api.get('/api/tiers', {
            params: params
        })
    }

}

export default new TiersService()