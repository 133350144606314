import api from "./api"

class PractitionerCategoryService {

    async getAll() {
        return api.get('/api/practitionerCategories')
    }

    async save(category) {
        return api.post('/api/practitionerCategories', category)
    }

    async update(id, category) {
        return api.put('/api/practitionerCategories/' + id, category)
    }

    async removeById(id) {
        return api.delete('/api/practitionerCategories/' + id)
    }

}

export default new PractitionerCategoryService()