<template>
    <div class="card mb-4">
        <div class="card-header pb-0">  
            <div class="row">
                <div class="col-6 d-flex align-items-center">
                    <p class="text-uppercase text-sm mb-0">{{  $t('section.address') }}</p>
                </div>
                <div v-if="isAdmin" class="col-6 text-end">
                    <button v-if="id" type="button" class="btn btn-outline-primary btn-sm m-0"
                        @click.prevent="openModal"
                    >
                        <font-awesome-icon icon="fa fa-plus" /> {{ $t('button.update') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body py-2">
            <div class="row">
                <div class="col-12">
                    <p class="text-sm font-weight-bold mb-0">{{ modelValue.streetNumber }} {{ modelValue.streetName }}</p>
                    <p class="text-sm mb-0">{{ modelValue.zipCode }} {{ modelValue.city }}</p>
                    <p class="text-sm">{{ modelValue.country }}</p>
                </div>
            </div>
            <ModalAddress ref="modalAddress"
                @update:address="update"
                v-model="address"
            />
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth.store'

import ModalAddress from '@/components/Modals/ModalAddress.vue'

export default {
    name: 'CardContact',
    components: {
        ModalAddress
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: ['update:address'],
    data() {
        return {
            address: null
        }
    },
    setup() {
        const authStore = useAuthStore()
        const { isAdmin } = storeToRefs(authStore)

        return { isAdmin }
    },
    methods: {
        openModal() {
            this.address = this.modelValue
            this.$nextTick(() => {
                this.$refs.modalAddress.show()
            })
        },
        update(address) {
            this.$emit('update:address', address)
        }
    }
}
</script>
