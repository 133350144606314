<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <div>
        <KeepAlive :include="['SearchPage', 'OrganizationsList', 'PractitionersList']">
          <component :is="Component" />
        </KeepAlive>
      </div>
    </transition>
  </router-view>
</template>

<script>
  export default {
    name: 'MainContent',
  }
</script>
