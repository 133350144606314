<template>
  <loading v-model:active="loading" :can-cancel="false" :is-full-page="true" />
  <div class="row">
    <div class="col-lg-8">
      <div class="card mb-4">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="text-uppercase text-sm">{{  $t('section.information') }}</p>
          </div>
        </div>
        <div class="card-body py-2">
          <form>
            <div v-if="healthcareService.id" class="row" style="display: none;">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="Id" class="control-label">{{  $t('field.id') }}</label>
                  <input type="text" class="form-control"
                      id="Id"
                      aria-describedby="addon-right addon-left"
                      v-model="healthcareService.id"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name" class="control-label">{{  $t('field.name') }}</label>
                  <input type="text" class="form-control" autocomplete="on"
                      id="name"
                      v-bind:placeholder="$t('placeholder.name')"
                      aria-describedby="addon-right addon-left"
                      v-model="healthcareService.name"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select-store
                      v-bind:placeholder="$t('placeholder.healthcareServiceCategory')"
                      v-bind:label="$t('field.category')"
                      keyvalue="name"
                      optionvalue="name"
                      v-model="healthcareService.category" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="organizationTags" class="control-label">{{  $t('field.organization') }}</label>
                  <tags-input element-id="organizationstags"
                      ref="organizationTagsInput"
                      inputId="organizationTags"
                      :placeholder="$t('placeholder.organizationsTags')"
                      :existing-tags="orgs"
                      id-field="url"
                      text-field="name"
                      :value="providedBySelectedNodes"
                      :typeahead-activation-threshold="3"
                      :typeahead="true"
                      :typeahead-hide-discard="true"
                      :hide-input-on-limit="true"
                      :only-existing-tags="true"
                      :limit=1
                      wrapper-class="form-control"
                      @tag-added="addProvidedBy"
                      @tag-removed="removeProvidedBy"
                      />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="specializationTags" class="control-label">{{  $t('field.specializations') }}</label>
                  <tags-input element-id="specializationstags"
                      ref="specializationsTagsInput"
                      inputId="specializationTags"
                      :placeholder="$t('placeholder.specializationsTags')"
                      :existing-tags="specializations"
                      id-field="code"
                      text-field="name"
                      :value="specializationsSelectedNodes"
                      :typeahead-activation-threshold="3"
                      :typeahead="true"
                      :typeahead-hide-discard="true"
                      :hide-input-on-limit="true"
                      :add-tags-on-comma="true"
                      wrapper-class="form-control"
                      @tag-added="addSpecialization"
                      @tag-removed="removeSpecialization"
                      />
                </div>
              </div>
            </div>
          
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="extraDetails" class="control-label">{{  $t('field.extraDetails') }}</label>
                  <textarea type="text" rows="6" class="form-control"
                      id="extraDetails"
                      v-bind:placeholder="$t('placeholder.healthcareServiceExtraDetails')"
                      aria-describedby="addon-right addon-left"
                      v-model="healthcareService.extraDetails"/>
                </div>
              </div>
            </div>

            <div v-if="isAdmin" class="row">
              <div class="col-md-12">
                <div class="text-center">
                  <button v-if="healthcareService.id" type="submit" class="btn btn-info btn-fill float-right" @click.prevent="update">
                    {{  $t('button.update') }}
                  </button>
                  <button v-else type="submit" class="btn btn-info btn-fill float-right" @click.prevent="save">
                    {{  $t('button.add') }}
                  </button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <CardAddress
          :id="healthcareService.id"
          @update:address="onUpdateAddress"
          v-model="healthcareService.address"
      />
      <CardContact
          :id="healthcareService.id"
          @add:contact="onAddContact"
          @update:contact="onUpdateContact"
          @remove:contact="onRemoveContact"
          v-model="healthcareService.contacts"
      />
      <CardTelecom
          :id="healthcareService.id"
          @add:telecom="onAddTelecom"
          @update:telecom="onUpdateTelecom"
          @remove:telecom="onRemoveTelecom"
          v-model="healthcareService.telecom"
      />
    </div>
  </div>
</template>

<script>
  import { provide } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { i18n } from '@/helpers/i18n'
  import { useHealthcareServiceStore } from '@/stores/healthcareService.store'
  import { useHealthcareServiceCategoryStore } from '@/stores/healthcareServiceCategory.store'
  import { useOrganizationStore } from '@/stores/organization.store'
  import { useSpecializationStore } from '@/stores/specialization.store'
  import { useAuthStore } from '@/stores/auth.store'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'
  import SelectStore from '@/components/Select/SelectStore.vue'
  import TagsInput from '@/components/Tags/TagsInput.vue'
  import CardAddress from '@/components/Cards/CardAddress.vue'
  import CardContact from '@/components/Cards/CardContact.vue'
  import CardTelecom from '@/components/Cards/CardTelecom.vue'
  
  export default {
    components: {
      Loading,
      SelectStore,
      TagsInput,
      CardAddress,
      CardContact,
      CardTelecom
    },
    data () {
      return {
        providedBySelectedNodes: [],
        specializationsSelectedNodes: [],
      }
    },
    setup() {
      const route = useRoute()
      const router = useRouter()

      provide('select-store', useHealthcareServiceCategoryStore())

      const organizationStore = useOrganizationStore()
      const { orgs } = storeToRefs(organizationStore)
      organizationStore.fetchList()

      const specializationStore = useSpecializationStore()
      const { specializations } = storeToRefs(specializationStore)
      specializationStore.fetchList()

      const store = useHealthcareServiceStore()
      const { healthcareService, loading } = storeToRefs(store)
      const { fetchById, reset } = store

      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      const id = route.params.id
      id !== '0' ? fetchById(id) : reset()

      const save = () => {
        store.save(healthcareService.value)
        route.push('/healthcareServices')
      }
      const update = () => {
        store.update(id, healthcareService.value)
      }
      const remove = () => {
        let resp = confirm(i18n.t('modal.remove.healthcareService'))
        if(resp) {
          store.remove(id)
          //router.back()
          router.push('/healthcareServices?refresh=true')
        }
      }
      const updateAddress = (id, address) => {
        store.updateAddress(id, address)
      }
      const addContact = (id, contact) => {
        store.addContact(id, contact)
      }
      const updateContact = (id, contact) => {
        store.updateContact(id, contact)
      }
      const removeContact = (id, contact) => {
        store.removeContact(id, contact)
      }
      const updateTelecom = (id, telecom) => {
        store.updateTelecom(id, telecom)
      }

      return {
        id,
        healthcareService,
        orgs,
        specializations,
        loading,
        save, update, remove,
        updateAddress,
        addContact, updateContact, removeContact,
        updateTelecom,
        isAdmin
      }
    },
    computed: {
      specializationsNames() {
        return this.specializationsSelectedNodes.map((item) => {
          return item.name
        })
      }
    },
    watch: {
      healthcareService() {
        if(this.healthcareService.providedBy) {
          this.providedBySelectedNodes = []
          let name = this.healthcareService.providedBy.name
          let url = this.healthcareService.providedBy.url
          let node = { url: url, name: name }
          this.providedBySelectedNodes.push(node)
        }
        if(this.healthcareService.specializations) {
          this.specializationsSelectedNodes = []
          let mytags = this.healthcareService.specializations.split(', ')
          mytags.forEach((item, index) => {
            let node = { code: index.toString(), name: item }
            this.specializationsSelectedNodes.push(node)
          })
        }
        if(!this.healthcareService.contacts) {
          this.healthcareService.contacts = []
        }
        if(!this.healthcareService.telecom) {
          this.healthcareService.telecom = []
        }
      }
    },
    methods: {
      addProvidedBy(tag) {
        this.providedBySelectedNodes.push(tag)
        this.healthcareService.providedBy = tag
      },
      removeProvidedBy(tag) {
        let index = this.providedBySelectedNodes.indexOf(tag.name)
        this.providedBySelectedNodes.splice(index, 1);
        this.healthcareService.providedBy = null
      },
      addSpecialization(tag) {
        let isMatch = this.specializationsSelectedNodes.find(item => {
          return item.name === tag.name
        })
        if(!isMatch) {
          this.specializationsSelectedNodes.push(tag);
          this.healthcareService.specializations = this.specializationsNames.join(', ')
        }
      },
      removeSpecialization(tag) {
        let index = this.specializationsSelectedNodes.indexOf(tag.name)
        this.specializationsSelectedNodes.splice(index, 1);
        this.healthcareService.specializations = this.specializationsNames.join(', ')
      },

      // handling address

      onUpdateAddress(address) {
        console.debug(address)
        this.updateAddress(this.id, address)
      },

      // handling contact single entry

      onAddContact(contact) {
        console.debug(contact)
        this.addContact(this.id, contact)
      },
      onUpdateContact(contact) {
        console.debug(contact)
        this.updateContact(this.id, contact)
      },
      onRemoveContact(contact) {
        console.debug(contact)
        this.removeContact(this.id, contact)
      },

      // handling telecom list
      
      onAddTelecom(telecom) {
        console.debug(telecom)
        this.healthcareService.telecom.push(telecom)
        this.updateTelecom(this.id, this.healthcareService.telecom)
      },
      onUpdateTelecom(telecom, oldValue) {
        console.debug(telecom)
        this.healthcareService.telecom = this.healthcareService.telecom.map(item => {
          if(item === oldValue) {
            return telecom
          }
          return item
        })
        this.updateTelecom(this.id, this.healthcareService.telecom)
      },
      onRemoveTelecom(telecom) {
        console.debug(telecom)
        this.healthcareService.telecom = this.healthcareService.telecom.filter(item => item !== telecom)
        this.updateTelecom(this.id, this.healthcareService.telecom)
      },
    }
  }
</script>
