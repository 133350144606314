import { defineStore } from 'pinia'
import { router } from '@/router'

import AuthService from '@/services/auth.service'
import TokenService from '@/services/token.service'

import { Role } from '@/services/role'
import { useAlertStore } from '@/stores/alert.store'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: TokenService.getUser(),
    returnUrl: null
  }),
  getters: {
    getUser: (state) => {
      return state.user
    },
    isInRole: (state) => (roles) => {
      return state.user?.roles?.includes(roles)
    },
    isAdmin: (state) => {
      return state.isInRole(Role.Admin)
    },
    isSuperAdmin: (state) => {
      return state.isInRole(Role.SuperAdmin)
    }
  },
  actions: {
    async login(user, returnUrl) {
      try {
        const response = await AuthService.login(user)
        this.user = response
        this.returnUrl = returnUrl

        // redirect to previous url or default to home page
        router.push(this.returnUrl || '/') 

      } catch (error) {
        const alertStore = useAlertStore()
        alertStore.error(error)
      }
    },
    getAccessToken() {
      return TokenService.getLocalAccessToken()
    },
    async refreshToken(token) {
      try {
        await AuthService.refreshToken(token)
        // TODO: manage this by native browser cache
        /*TokenService.updateLocalAccessToken(response.data.accessToken)
        return response.data*/
      } catch (error) {
        this.logout()
        return Promise.reject(error)
      }
    },
    logout() {
      AuthService.logout()
      this.user = null;
      // TODO: manage this by native browser cache
      sessionStorage.removeItem('healthcareServiceCategories')
      sessionStorage.removeItem('organizationCategories')
      sessionStorage.removeItem('practitionerCategories')
      sessionStorage.removeItem('specializations')
      //
      router.push('/login');
    },
    async reset(token, credentials) {
      try {
        const response = await AuthService.reset(token, credentials)
        console.debug("credentials reset data : ", response.data)
        return response.data
      } catch (error) {
        const alertStore = useAlertStore()
        alertStore.error(error)
      }
    }
  }
})
