<template>
    <label for="store" class="control-label">{{label}}</label>
    <select id="store" class="form-control"
            :multiple="multiple"
            :value="modelValue"
            @input="updateValue">
        <option disabled value="">{{ placeholder }}</option>
        <option v-for="item in filteredData" :key="item" :value="itemValue(item)">
            {{ itemOption(item) }}
        </option>
    </select>
</template>

<script>
import { inject } from 'vue'
import { storeToRefs } from 'pinia'

export default {
    name: 'SelectStore',
    props: {
        label: String,
        group: String,
        keyvalue: String,
        pageable: { type: Boolean, default: false },
        optionvalue: String,
        placeholder: String,
        multiple: [Boolean],
        modelValue: String
    },
    emits: ['update:modelValue'],
    data() {
        return {
            filteredData: []
        }
    },
    setup() {
        const store = inject('select-store')
        const { data } = storeToRefs(store)
        const { fetch } = store
        return { data, fetch }
    },
    created() {
        this.fetchData()
    },
    watch: {
        group(newValue) {
            this.filterData(newValue)
        }
    },
    methods: {
        async fetchData () {
            await this.fetch()
                .then(() => this.filterData(this.group))
                .catch()
        },
        filterData(filter) {
            if(this.group) {
                this.filteredData = this.data
                    .filter((e) => e.group === filter)
                    .map((e) => { return e })
            } else {
                this.filteredData = this.data
            }
        },
        itemValue(item) {
            if(this.keyvalue != null) {
                return item[this.keyvalue]
            }
            return item.name
        },
        itemOption(item) {
            if(this.optionvalue != null) {
                return item[this.optionvalue]
            }
            return item.name
        },
        updateValue(event) {
            console.debug("value = " + event.target.value)
            this.$emit('update:modelValue', event.target.value)
        },
    }
}
</script>