<template>
    <div class="modal fade" ref="modalAddress" id="modalAddress" aria-hidden="true" aria-labelledby="modalAddressLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="card card-plain">
                        <div class="card-header pb-0 text-left">
                            <h3 class="font-weight-bolder text-primary text-gradient" id="modalAddressLabel">{{  $t('modal.address.title') }}</h3>
                            <p class="mb-0">{{  $t('modal.address.subtitle') }}</p>
                        </div>
                        <div class="card-body pb-3">
                            <form>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="control-label">{{  $t('field.address-long') }}</label>
                                            <GMapAutocomplete
                                                class="form-control form-control-sm"
                                                ref="gmapAutocomplete"
                                                v-bind:placeholder="$t('placeholder.address-long')"
                                                @place_changed="setPlace"
                                            >
                                            </GMapAutocomplete>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">{{  $t('button.close') }}</button>
                                    <button type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="updateAddress">{{  $t('button.update') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

const emptyAddress = {
    streetNumber: '',
    streetName: '',
    city: '',
    country: '',
    zipCode: '',
    location: ''
}

export default {
    name: 'ModalAddress',
    props: {
        modelValue: {
            type: Object,
            //required: true
        }
    },
    emits: ['update:address'],
    data () {
        return {
            address: emptyAddress
        }
    },
    methods: {
        initGmapAutocomplete() {
            if(this.modelValue) {
                if (this.$refs.gmapAutocomplete?.$refs.input) {
                    let value = this.modelValue
                    let fullname = value.streetNumber + ' ' + value.streetName + ' ' + value.zipCode + ' ' + value.city + ', ' + value.country
                    this.$refs.gmapAutocomplete.$refs.input.value = fullname
                }
            }
        },
        show() {
            let modal = new Modal(this.$refs.modalAddress)
            modal.show()
            this.initGmapAutocomplete()
        },
        /*setupInputBox() {
            if(this.modelValue) {
                if (this.$refs.gmapAutocomplete?.$refs.input) {
                    let value = this.modelValue
                    let fullname = value.streetNumber + ' ' + value.streetName + ', ' + value.zipCode + ' ' + value.city
                    this.$refs.gmapAutocomplete.$refs.input.value = fullname
                }
            } else {
                this.address = emptyAddress
            }
        },*/
        setPlace(place) {
            this.address.streetNumber = place.address_components[0].long_name
            this.address.streetName = place.address_components[1].long_name
            this.address.city = place.address_components[2].long_name
            this.address.country = place.address_components[5].long_name
            this.address.zipCode = place.address_components[6].long_name
            let location = { 
                type: "Point",
                coordinates: [place.geometry.location.lat(), place.geometry.location.lng()]
            }
            this.address.location = location
        },
        updateAddress() {
            this.$emit('update:address', this.address)
        },
    }
}
</script>
