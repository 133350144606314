<template>
    <label for="attribut" class="control-label">{{label}}</label>
    <select id="attribut" class="form-control"
            :value="modelValue"
            @input="updateValue">
        <option disabled value="">{{ placeholder }}</option>
        <option v-for="item in data" :key="item" :value="item">
            {{ item }}
        </option>
    </select>
</template>

<script>
export default {
    name: 'SelectEnum',
    props: {
        attribut: String,
        label: String,
        enumeration: Array,
        placeholder: String,
        modelValue: String
    },
    emits: ['update:modelValue'],
    computed: {
        data: {
            get() {
                return this.enumeration
            }
        }
    },
    methods: {
        updateValue(event) {
            console.debug("value" + event.target.value)
            this.$emit('update:modelValue', event.target.value)
        },
    }
}
</script>