<template>
  <router-link v-if="render" custom v-slot="{ href, isExactActive }" v-bind="$attrs" :to="to">
    <a class="nav-link" :class="{ 'active': isExactActive }" :href="this.sanitizeUrl(href)">
      <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
        <font-awesome-icon :icon="icon" style="color: rgb(94, 114, 228);" />
      </div>
      <span class="nav-link-text ms-1">
        <slot>
          {{ title }}
        </slot>
      </span>
    </a>
  </router-link>
</template>

<script>
  export default {
    inheritAttrs: false,
    inject: {
      autoClose: {
        default: false
      }
    },
    props: {
      to: {
        type: Object,
        default: null
      },
      icon: {
        type: Array
      },
      title: {
        type: String,
        default: '',
      },
      render: {
        type: Boolean,
        default: true
      }
    },
    setup() {
        const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl
        return { sanitizeUrl }
    },
    methods: {
      hideSidebar () {
        if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
          this.$sidebar.displaySidebar(false)
        }
      },
      sanitize(href) {
        return this.sanitizeUrl(href)
      }
    }
  }
</script>
