import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import TiersService from '@/services/tiers.service'

export const useTiersStore = defineStore('tiers', {
    state: () => ({
        tiers: [],
        totalPages: 0,
        loading: false,
        //showResults: false
    }),
    getters: {
        autocompleteList: (state) => state.tiers.map((item) => {
            let node = [ item.name, item.category, item.agreement ]
            let entry = node.join(', ')
            return entry
        })
    },
    actions: {
        async fetchAll(params) {
            if(params === undefined) return
            this.tiers = []
            this.totalPages = 0
            this.loading = true
            //this.showResults = false
            await TiersService.getAll(params)
                .then(response => {
                    this.tiers = response.data.tiers
                    this.totalPages = response.data.totalPages
                    if(!this.tiers.length) {
                        let message = i18n.t('message.empty-list')
                        useAlertStore().info(message)
                    }
                    //this.showResults = true
                })
                .catch(error => {
                    console.error(error)
                    useAlertStore().error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
})