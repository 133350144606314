import api from "./api";

class PractitionerService {

    async getAll(params) {
        return api.get('/api/practitioners', {
            params: params
        })
    }

    async getById(id) {
        return api.get('/api/practitioners/' + id)
    }

    async save(practitioner) {
        return api.post('/api/practitioners', practitioner)
    }

    async update(id, practitioner) {
        return api.put('/api/practitioners/' + id, practitioner)
    }

    // adressing address

    async updateAddress(id, address) {
        return api.put('/api/practitioners/' + id + '/address', address)
    }

    // adressing telecom through value list

    async updateTelecom(id, telecom) {
        return api.put('/api/practitioners/' + id + '/telecom', telecom)
    }

    async removeTelecom(id) {
        return api.delete('/api/practitioners/' + id + '/telecom')
    }

    // addressing managing entity through one single value

    async addManagingEntity(id, managingEntity) {
        return api.patch('/api/practitioners/' + id + '/managingEntities', managingEntity)
    }

    async updateManagingEntity(id, managingEntity) {
        return api.patch('/api/practitioners/' + id + '/managingEntities', managingEntity)
    }

    async removeManagingEntity(id, managingEntity) {
        return api.delete('/api/practitioners/' + id + '/managingEntities/' + managingEntity.id)
    }

    async removeById(id) {
        return api.delete('/api/practitioners/' + id)
    }

}

export default new PractitionerService()