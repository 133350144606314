<template>
  <div v-if="platform" class="bg-white px-3">
    <p class="mb-0"><small>{{ platform }}</small></p>
  </div>
  <component :is="$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store'

export default {
  name: 'App',
  data() {
    return {
      env: process.env.NODE_ENV,
      mode: process.env.VUE_APP_MODE,
      platform: ''
    }
  },
  setup() {
    const authStore = useAuthStore()
    return { authStore }
  },
  created() {
    if(this.mode !== 'prod') {
      this.platform = this.env +' - ' + this.mode
    }
  },
  mounted () {
    document.body.classList.add('g-sidenav-show', 'bg-gray-100')
  },
  methods: {
    beforeDestroy() {
      this.authStore.logout()
    },
  }
}
</script>

<!--style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700|Google+Sans+Text:400&amp;lang=fr');
</style-->
