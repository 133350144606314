<template>
    <div class="modal fade" ref="managingEntityModal" id="managingEntityModal" tabindex="-1" role="dialog" aria-labelledby="managingEntityModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="card card-plain">
                        <div class="card-header pb-0 text-left">
                            <h3 class="font-weight-bolder text-primary text-gradient">{{  $t('modal.managingEntity.title') }}</h3>
                            <p class="mb-0">{{  $t('modal.managingEntity.subtitle') }}</p>
                        </div>
                        <div class="card-body pb-3">
                            <form role="form text-left">
                                <div class="form-group">
                                    <label for="organization-tags" class="control-label">{{  $t('field.organization') }}</label>
                                    <tags-input element-id="organizationsTags"
                                        ref="organizationsTagsInput"
                                        inputId="organization-tags"
                                        :placeholder="$t('placeholder.organizationsTags')"
                                        :existing-tags="orgs"
                                        id-field="url"
                                        text-field="name"
                                        :value="organizationsSelectedNodes"
                                        :typeahead-activation-threshold="3"
                                        :typeahead="true"
                                        :typeahead-hide-discard="true"
                                        :hide-input-on-limit="true"
                                        :only-existing-tags="true"
                                        :limit=1
                                        wrapper-class="form-control"
                                        @tag-added="addTagOrganization"
                                        @tag-removed="removeTagOrganization"
                                    />
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <label for="healthcareService-tags" class="control-label">{{  $t('field.healthcareService') }}</label>
                                        <tags-input element-id="healthcareServicesTags"
                                            ref="healthcareServicesTagsInput"
                                            inputId="healthcareService-tags"
                                            :placeholder="$t('placeholder.healthcareServicesTags')"
                                            :existing-tags="services"
                                            id-field="url"
                                            text-field="name"
                                            :value="healthcareServicesSelectedNodes"
                                            :typeahead-activation-threshold="3"
                                            :typeahead="true"
                                            :typeahead-hide-discard="true"
                                            :hide-input-on-limit="true"
                                            :only-existing-tags="true"
                                            :limit=1
                                            wrapper-class="form-control"
                                            @tag-added="addTagHealthcareService"
                                            @tag-removed="removeTagHealthcareService"
                                        />
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">{{  $t('button.close') }}</button>
                                    <button v-if="upsert" type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="addManagingEntity">{{  $t('button.add') }}</button>
                                    <button v-else type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="updateManagingEntity">{{  $t('button.update') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

import { useOrganizationStore } from '@/stores/organization.store'
import { useHealthcareServiceStore } from '@/stores/healthcareService.store'
import { storeToRefs } from 'pinia'

import TagsInput from '@/components/Tags/TagsInput.vue'

export default {
    name: 'ModalManagingEntity',
    components: {
      TagsInput
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    emits: ['add:managingEntity', 'update:managingEntity'],
    setup() {
        const organizationStore = useOrganizationStore()
        const { orgs } = storeToRefs(organizationStore)
        organizationStore.fetchList()

        const healthcareServiceStore = useHealthcareServiceStore()
        const { services } = storeToRefs(healthcareServiceStore)
        healthcareServiceStore.fetchList()

        return { orgs, services }
    },
    computed: {
        managingEntity: {
            get() {
                return this.modelValue
            }
        },
        upsert: {
            get() {
                return !this.modelValue.id
            } 
        },
    },
    data() {
        return {
            organizationsSelectedNodes: [],
            healthcareServicesSelectedNodes: [],
        }
    },
    watch: {
        managingEntity() {
            console.debug(this.managingEntity)
            if(this.managingEntity.organization) {
                console.debug(this.managingEntity.organization)
                this.organizationsSelectedNodes = []
                this.organizationsSelectedNodes.push(this.managingEntity.organization)
            }
            if(this.managingEntity.healthcareService) {
                this.healthcareServicesSelectedNodes = []
                this.healthcareServicesSelectedNodes.push(this.managingEntity.healthcareService)
            }
        },
        organizationsSelectedNodes() {
            if(this.organizationsSelectedNodes.length < 1) {
                this.healthcareServicesSelectedNodes = []
            }
        },
        healthcareServicesSelectedNodes() {
            if(this.healthcareServicesSelectedNodes.length < 1) {
                this.managingEntity.healthcareService = null
            }
        }
    },
    methods: {
        /*filter() {
            console.debug("filtering.......")
            this.filetredHealthcareServices = this.healthcareServices
        },*/
        // TODO: add validate on form that organization is not null
        show() {
            let modal = new Modal(this.$refs.managingEntityModal)
            modal.show()
        },
        addManagingEntity() {
            this.$emit('add:managingEntity', this.managingEntity)
        },
        updateManagingEntity() {
            this.$emit('update:managingEntity', this.managingEntity)
        },
        addTagOrganization(tag) {
            console.debug(tag)
            this.organizationsSelectedNodes.push(tag);
            this.managingEntity.organization = tag
        },
        removeTagOrganization() {
            this.organizationsSelectedNodes = []
            this.managingEntity.organization = null
        },
        addTagHealthcareService(tag) {
            let isMatch = this.healthcareServicesSelectedNodes.find(item => {
                return item.name === tag.name
            })
            if(!isMatch) {
                this.healthcareServicesSelectedNodes.push(tag);
                this.managingEntity.healthcareService = tag
            }
        },
        removeTagHealthcareService() {
            this.healthcareServicesSelectedNodes = []
            this.managingEntity.healthcareService = null
        },
    }
}
</script>
