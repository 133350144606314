<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <div v-if="embedded" class="row">
                <div class="col-6 d-flex align-items-center">
                    <label for="contact-telecom mb-0">{{  $t('section.telecom') }}</label>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-6 d-flex align-items-center">
                    <p class="text-uppercase text-sm mb-0">{{  $t('section.telecom') }}</p>
                </div>
                <div v-if="isAdmin" class="col-6 text-end">
                    <button v-if="id" type="button" class="btn btn-outline-primary btn-sm m-0"
                            data-bs-toggle="modal" data-bs-target="#telecomModal"
                            @click.prevent="openModal">
                        <font-awesome-icon icon="fa fa-plus" /> {{ $t('button.add') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body py-2 mb-2">
            <ListTelecom class="table-hover"
                @edit:telecom="edit"
                @remove:telecom="$emit('remove:telecom', $event)"
                :data="modelValue"
            />
            <button v-if="embedded" type="button" class="btn btn-outline-primary btn-sm mb-0 ms-1 px-3 py-0" style="margin-top: -0.5rem;"
                    data-bs-toggle="modal" data-bs-target="#contactPointModal">
                <font-awesome-icon icon="fa fa-plus" />
            </button>
            <ModalTelecom v-if="!noModal" ref="telecomModal"
                :upsert="upsert"
                @add:telecom="add"
                @update:telecom="update"
                :modelValue="telecom"
            />
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth.store'

import ListTelecom from '@/components/List/ListTelecom.vue'
import ModalTelecom from '@/components/Modals/ModalTelecom.vue'

const initialState = {
    system: '',
    value: '',
    use: ''
}

export default {
    name: 'CardTelecom',
    components: {
        ListTelecom,
        ModalTelecom
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: ''
        },
        embedded: {
            type: Boolean,
            default: false
        },
        noModal: {
            type: Boolean,
            default: false
        },
    },
    emits: ['add:telecom', 'update:telecom', 'remove:telecom'],
    data() {
        return {
            telecom: initialState,
            oldValue: null,
            upsert: false,
            /*columns: [
                { key: 'system', label: this.$t('field.system'), },
                { key: 'value', label: this.$t('field.value'), },
                { key: 'use', label: this.$t('field.use'), }
            ]*/
        }
    },
    setup() {
      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      return { isAdmin }
    },
    methods: {
        openModal() {
            this.telecom = JSON.parse(JSON.stringify(initialState))
            this.upsert = true
        },
        edit(telecom, oldValue) {
            this.telecom = telecom
            this.oldValue = oldValue
            this.upsert = false
            this.$refs.telecomModal.show()
        },
        add() {
            console.debug("addtelecom about to emit", this.telecom)
            this.$emit('add:telecom', this.telecom)
        },
        update() {
            console.debug("updatetelecom about to emit", this.telecom)
            this.$emit('update:telecom', this.telecom, this.oldValue)
        }
    }
}
</script>
