<template>
  <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4"
      id="sidenav-main">
    <div v-if="logo || title" class="sidenav-header text-center">
      <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
      <a class="navbar-brand m-0" href="/search">
        <img v-if="logo" src="/img/vue-logo.png" class="navbar-brand-img h-100 ms-1" alt="main_logo">
        <span v-if="title" class="font-weight-bold">{{ title }}</span>
        <!--font-awesome-icon :icon="['fa', 'fa-times']" class="" @click.prevent="hideSidebar"/-->
      </a>
      <hr class="horizontal dark mt-0">
    </div>
    <div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
      <slot name="content"></slot>
      <slot>
        <ul class="navbar-nav">
          <li v-for="(link,index) in sidebarLinks" :key="index" class="nav-item">
            <sidebar-title v-if="link.subtitle && isInRole(link.render)"
                :title="link.subtitle"
            />
            <sidebar-link v-else-if="isInRole(link.render)"
                :to="link.path"
                :icon="link.icon"
                :title="link.name"
            />
          </li>
        </ul>
      </slot>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </aside>
</template>

<script>
  import TokenService from '@/services/token.service'

  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      backgroundColor: {
        type: String,
        default: 'purple',
        validator: (value) => {
          let acceptedValues = ['primary', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundImage: {
        type: String,
        default: ''
      },
      logo: {
        type: Boolean,
        default: false
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      sidebarStyle () {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        }
      },
    },
    created() {
      this.loggedInUser = TokenService.getUser()
    },
    methods: {
      isInRole(role) {
        return !role || this.loggedInUser?.roles?.includes(role)
      },
    }
  }
</script>
