<template>
    <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-em-6 me-5">
        <router-link v-for="(item, index) in breadcrumbs" :key="index"
                :to="item.to" custom v-slot="{ href, isExactActive }"
                >
            <li class="breadcrumb-item text-sm" :class="{ 'active': isExactActive }">
                <a class="opacity-5 text-white" :href="this.sanitize(href)">
                    {{ item.label }}
                </a>
            </li>        
        </router-link>
    </ol>
</template>

<script>
export default {
    name: "breadcrumb-list",
    computed: {
        breadcrumbs() {
            const route = this.$route
            return [{
                label: this.$t('breadcrumb.' + route.name),
                to: route.href
            }]
        },
    },
    setup() {
        const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl
        return { sanitizeUrl }
    },
    methods: {
        sanitize(href) {
            return this.sanitizeUrl(href)
        }
    }
}
</script>