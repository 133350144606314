import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import PractitionerService from '@/services/practitioner.service'

const emptyAddress = {
    streetNumber: '',
    streetName: '',
    city: '',
    country: '',
    zipCode: ''
}

const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    codeADELI: '',
    codeRPPS: '',
    exercice: '',
    agreement: '',
    category: '',
    specializations: '',
    managingEntities: [],
    acceptNewPatients: '',
    rp: '',
    deliverHomeCare: '',
    address: emptyAddress,
    telecom: [],
}

export const usePractitionerStore = defineStore('practitioner', {
    state: () => ({
        practitioners: [],
        totalPages: 0,
        practitioner: JSON.parse(JSON.stringify(initialState)),
        id: 0,
        loading: false
    }),
    getters: {
        getPractitionersPerCategory(state) {
            return (category) => state.practitioners.filter((practitioner) => practitioner.category === category)
        },
        getPractitionerMobileNumbers(state) {
            console.debug(state.practitioner)
            let telecom = state.practitioner.telecom
            console.debug(telecom)
            let numbers = telecom?.filter((t) => t.system === "MOBILE")
            console.debug(numbers)
            return numbers
        }
        // computed properties...
    },
    actions: {
        reset() {
            this.practitioner = JSON.parse(JSON.stringify(initialState))
        },
        async fetchAll(params = {}) {
            this.practitioners = []
            this.totalPages = 0
            this.loading = true
            await PractitionerService.getAll(params)
                .then(response => {
                    this.practitioners = response.data.practitioners
                    this.totalPages = response.data.totalPages
                    if(!this.practitioners.length) {
                        let message = i18n.t('message.empty-list')
                        useAlertStore().info(message)
                    }
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async fetchById(id) {
            this.loading = true
            await PractitionerService.getById(id)
                .then(response => {
                    this.practitioner = response.data
                    if(!this.practitioner.address) {
                        this.practitioner.address = emptyAddress
                    }
                    if(!this.practitioner.telecom) {
                        this.practitioner.telecom = []
                    }              
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async save(practitioner) {
            await PractitionerService.save(practitioner)
                .then((response) => {
                    this.practitioner = response.data
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async update(id, practitioner) {
            await PractitionerService.update(id, practitioner)
                .then((response) => {
                    this.practitioner = response.data
                    let message = i18n.t('message.practitioner.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async updateAddress(id, address) {
            await PractitionerService.updateAddress(id, address)
                .then((response) => {
                    this.practitioner = response.data
                    let message = i18n.t('message.address.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async updateTelecom(id, telecom) {
            await PractitionerService.updateTelecom(id, telecom)
                .then((response) => {
                    this.practitioner = response.data
                    let message = i18n.t('message.telecom.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async addManagingEntity(id, managingEntity) {
            await PractitionerService.addManagingEntity(id, managingEntity)
                .then((response) => {
                    this.practitioner = response.data
                    let message = i18n.t('message.managingEntity.added')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async updateManagingEntity(id, managingEntity) {
            await PractitionerService.updateManagingEntity(id, managingEntity)
                .then((response) => {
                    this.practitioner = response.data
                    let message = i18n.t('message.managingEntity.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async removeManagingEntity(id, managingEntity) {
            await PractitionerService.removeManagingEntity(id, managingEntity)
                .then((response) => {
                    this.practitioner = response.data
                    let message = i18n.t('message.managingEntity.removed')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async remove(id) {
            await PractitionerService.removeById(id)
                .then(() => {
                    let message = i18n.t('message.practitioner.removed')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        }
    }
})