import { createWebHistory, createRouter } from "vue-router"

import { useAlertStore } from '@/stores/alert.store'
import { Role } from '@/services/role'
import TokenService from  '@/services/token.service'

// Error Views
import ErrorLayout from '@/layouts/error/ErrorLayout.vue'
import NotFoundPage from '@/views/Error/NotFound.vue'
import NotAllowedPage from '@/views/Error/NotAllowed.vue'

// Layouts
import AuthLayout from '@/layouts/auth/AuthLayout.vue'
import MainLayout from '@/layouts/main/MainLayout.vue'

// Auth views
import LoginForm from '@/views/Login/LoginForm.vue'
import PasswordForm from '@/views/Login/PasswordForm.vue'
import CheckEmail from '@/views/Login/CheckEmailPage.vue'
import ResetForm from '@/views/Login/ResetForm.vue'

// Main views
import Search from '@/views/Search/SearchPage.vue'
import OrganizationsList from '@/views/Organization/OrganizationsList.vue'
import OrganizationForm from '@/views/Organization/OrganizationForm.vue'
import HealthcareServicesList from '@/views/HealthcareService/HealthcareServicesList.vue'
import HealthcareServiceForm from '@/views/HealthcareService/HealthcareServiceForm.vue'
import PractitionersList from '@/views/Practitioner/PractitionersList.vue'
import PractitionerForm from '@/views/Practitioner/PractitionerForm.vue'

const routes = [
  { path: '/', name: 'Home', component: MainLayout, redirect: '/search',
    children: [
      {
        path: 'search',
        name: 'Search',
        component: Search,
        meta: { breadcrumb: '', authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'secured-messaging',
        name: 'MailList',
        component: () => import('@/views/Mail/MailList.vue'),
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'secured-messaging/:id',
        name: 'MailForm',
        component: () => import('@/views/Mail/MailForm.vue'),
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'map',
        name: 'Map',
        component: () => import('@/views/Map/Map.vue'),
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'chat',
        name: 'Chat',
        component: () => import('@/views/Chat/Chat.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      },
      {
        path: 'assistant',
        name: 'Assistant',
        component: () => import('@/views/Assistant/Assistant.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      },
      {
        path: 'prescription',
        name: 'PrescriptionList',
        component: () => import('@/views/Prescription/PrescriptionList.vue'),
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'user',
        name: 'UserPage',
        component: () => import('@/views/User/UserPage.vue'),
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'users',
        name: 'UsersList',
        component: () => import('@/views/User/UsersList.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      },
      {
        path: 'hospitals',
        name: 'HospitalsList',
        component: OrganizationsList,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'healthcenters',
        name: 'HealthcentersList',
        component: OrganizationsList,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'psychiatry',
        name: 'PsychiatryList',
        component: OrganizationsList,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'othercenters',
        name: 'OthercentersList',
        component: OrganizationsList,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'organizations/:id',
        name: 'OrganizationForm',
        component: OrganizationForm,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'healthcareServices',
        name: 'HealthcareServicesList',
        component: HealthcareServicesList,
        meta: { authorize: [Role.SuperAdmin, Role.Practitioner] },
      },
      {
        path: 'healthcareServices/:id',
        name: 'HealthcareServiceForm',
        component: HealthcareServiceForm,
        meta: { authorize: [Role.SuperAdmin, Role.Practitioner] }
      },
      {
        path: 'practitioners',
        name: 'PractitionersList',
        component: PractitionersList,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'practitioners/:id',
        name: 'PractitionerForm',
        component: PractitionerForm,
        meta: { authorize: [Role.Admin, Role.Practitioner] }
      },
      {
        path: 'organizationGroups',
        name: 'OrganizationGroupList',
        component: () => import('@/views/Organization/OrganizationGroupList.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      },
      {
        path: 'organizationCategories',
        name: 'OrganizationCategoryList',
        component: () => import('@/views/Organization/OrganizationCategoryList.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      },
      {
        path: 'practitionerCategories',
        name: 'PractitionerCategoryList',
        component: () => import('@/views/Practitioner/PractitionerCategoryList.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      },
      {
        path: 'specializations',
        name: 'SpecializationList',
        component: () => import('@/views/Specialization/SpecializationList.vue'),
        meta: { authorize: [Role.SuperAdmin] }
      }
    ]
  },
  { path: '/login', component: LoginForm, meta: { layout: AuthLayout } },
  { path: '/password', component: PasswordForm, meta: { layout: AuthLayout } },
  { path: '/password/check-email', component: CheckEmail, meta: { layout: AuthLayout } },
  { path: '/password/update', component: ResetForm, meta: { layout: AuthLayout } },
  { path: '/403', component: NotAllowedPage, meta: { layout: ErrorLayout } },
  { path: '/:catchAll(.*)', component: NotFoundPage, meta: { layout: ErrorLayout } }
]

export const router = createRouter({
  history: createWebHistory(),
  //linkActiveClass: 'active',
  routes
})

router.beforeEach(async (to, from, next) => {

  // clear alert on route change
  const alertStore = useAlertStore()
  alertStore.clear()

  const { authorize } = to.meta
  const authRequired = (authorize?.length > 0)
  const loggedInUser = TokenService.getUser()
  
  // trying to access a restricted page and not logged in
  // redirect to login page with the return url
  if(authRequired && !loggedInUser) {
    return next({ path: '/login', query: { returnUrl: to.path } })
  }

  // trying to access a restricted page and not in role
  // redirect to page 403
  if(authRequired && !authorize.some(item => loggedInUser.roles.includes(item))) {
    return next({ path: '/403' })
  }

  next()
})
