import { i18n } from "@/helpers/i18n"
import axiosInstance from "./api"
import { useAuthStore } from '@/stores/auth.store'

const setup = () => {

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = useAuthStore().getAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config

      if (originalConfig.url !== "/auth/signin" && error.response) {
        // Access Token was expired
        if (error.response.status === 401 &&
            error.response.data.message === 'Unauthorized access !' &&
            !originalConfig._retry) {
          
          console.debug('--------- Error 401 - Unauthorized - Token expiration -----------------')
          originalConfig._retry = true;

          try {
            await useAuthStore().refreshToken()
            console.debug('token has been refreshed')
            return axiosInstance(originalConfig)
          } catch(refreshError) {
            console.debug('token has not been refreshed :', refreshError)
            return Promise.reject(refreshError)
          }
        }

        // Other error not related to token expiration
        if (error.response.status) {
          console.debug('interceptor error', error)
          
          let errorMessage = error.response.data.message
          if(!errorMessage) errorMessage = i18n.t('error.unknown-error')

          let errorStatus = ''
          switch (error.response.status) {
            case 400:
              errorStatus = i18n.t('error.bad-request')
              break
            case 401:
              errorStatus = i18n.t('error.unauthorized')
              break
            case 403:
              errorStatus = i18n.t('error.forbidden')
              break
            case 404:
              errorStatus = i18n.t('error.not-found')
              break
            case 500:
              errorStatus = i18n.t('error.internal-server-error')
              break
            default:
              errorStatus = i18n.t('error.unknown-error')
              break
          }
          return Promise.reject(new Error(errorStatus + ' - ' + errorMessage))
        }
      }
      return Promise.reject(new Error(i18n.t('error.network-error')))
    }
  )
}

export default setup