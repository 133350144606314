<template>
    <div v-if="alerts && alerts.length > 0" class="row">
        <div class="col-12">
            <div v-for="(alert, index) in alerts" :key="index" class="alert-dismissible fade show" :class="alert.type">
                <span class="alert-text">{{ alert.message }}</span>
                <button type="button" class="btn-close mt-0" data-bs-dismiss="alert" aria-label="Close" @click="alertStore.removeAt(index)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import { useAlertStore } from '@/stores/alert.store'

const alertStore = useAlertStore()
const { alerts } = storeToRefs(alertStore)
</script>

<script>
export default {
    name: 'AlertContent'
}
</script>