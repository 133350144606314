import api from "./api";

class HealthcareServiceCategoryService {

    async getAll() {
        return api.get('/api/healthcareServiceCategories')
    }

    async save(category) {
        return api.post('/api/healthcareServiceCategories', category)
    }

    async update(id, category) {
        return api.put('/api/healthcareServiceCategories/' + id, category)
    }

    async removeById(id) {
        return api.delete('/api/healthcareServiceCategories/' + id)
    }

}

export default new HealthcareServiceCategoryService()