<template>
    <label for="collection" class="control-label">{{label}}</label>
    <select id="collection" class="form-control form-control-sm"
            :collection="collection"
            :multiple="multiple"
            :value="modelValue"
            @input="updateValue">
        <option disabled value="">{{ placeholder }}</option>
        <option v-for="item in filteredData" :key="item" :value="itemValue(item)">
            {{ itemOption(item) }}
        </option>
    </select>
</template>

<script>
import api from "@/services/api"

export default {
    name: 'select-collection',
    props: {
        label: String,
        collection: String,
        group: String,
        keyvalue: String,
        pageable: { type: Boolean, default: false },
        optionvalue: String,
        placeholder: String,
        multiple: [Boolean],
        modelValue: String
    },
    emits: ['update:modelValue'],
    data() {
        return {
            attribute: '',
            data: [],
            filteredData: []
        }
    },
    created() {
        this.fetchData()
    },
    watch: {
        group(newValue) {
            this.filterData(newValue)
        }
    },
    methods: {
        async fetchData () {
            this.loading = true
            await api.get('/api/' + this.collection, { params: { format: 'list' } })
                .then(response => {
                    this.data = response.data || []
                    this.filterData(this.group)
                })
                .catch(error => {
                    console.error(error)
                })
            this.loading = false
        },
        filterData(filter) {
            if(this.group) {
                this.filteredData = this.data.filter((e) => e.group === filter)
                .map((e) => { return e })
            } else {
                this.filteredData = this.data
            }
        },
        itemValue(item) {
            if(this.keyvalue != null) {
                return item[this.keyvalue]
            }
            return item.name
        },
        itemOption(item) {
            if(this.optionvalue != null) {
                return item[this.optionvalue]
            }
            return item.name
        },
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value)
        },
    }
}
</script>