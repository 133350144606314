<template>
  <h6 v-if="render" class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6 mt-3 text-primary">
    <slot>
      {{ title }}
    </slot>
  </h6>
</template>

<script>
  export default {
    inheritAttrs: false,
    inject: {
      autoClose: {
        default: false
      }
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      render: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      hideSidebar () {
        if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
          this.$sidebar.displaySidebar(false)
        }
      }
    }
  }
</script>
