<template>
  <loading v-model:active="loading"
          :can-cancel="false"
          :is-full-page="true" />

  <div class="row">
    <div class="col-12">
        <div class="card mb-4">
            <div class="card-header pb-0 p-3">
              <div class="row">
                <div class="col-6 d-felx align-items-center">
                  <h6>{{  $t('card.practitioners.title') }}</h6>
                </div>
                <div v-if="isAdmin" class="col-6 text-end">
                  <button type="submit" class="btn btn-primary btn-sm mb-0" @click.prevent="createNew()">
                    {{  $t('button.add') }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <select-collection
                        collection="practitionerCategories"
                        v-bind:placeholder="$t('placeholder.category')"
                        v-bind:label="$t('field.category')"
                        v-model="category" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <select-enum
                        :enumeration="pageSizes"
                        v-bind:label="$t('field.pageSizes')"
                        v-model="sizeValue" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive">
                <TableBase class="table-hover"
                        :columns="table.columns"
                        :data="practitioners"
                        :apiLink="apiLink">
                </TableBase>
              </div>
            </div>
            <div class="card-footer">
              <base-pagination :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="5"
                  @page-changed="setActivePage" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { storeToRefs } from 'pinia'
  import { usePractitionerStore } from '@/stores/practitioner.store'
  import { useAuthStore } from '@/stores/auth.store'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'
  import TableBase from '@/components/Tables/TableBase.vue'
  import SelectCollection from '@/components/Select/SelectCollection.vue'
  import SelectEnum from '@/components/Select/SelectEnum.vue'
  import BasePagination from '@/components/Pagination/BasePagination.vue'

  export default {
    components: {
      Loading,
      TableBase,
      SelectCollection,
      SelectEnum,
      BasePagination,
    },
    name: 'PractitionersList',
    data () {
      return {
        search: '',
        category: '',
        page: 1,
        sizeValue: '10',
        size: 10,
        pageSizes: [ 10, 20, 50 ],

        table: {
          columns: [
            {
              key: 'practitioner',
              label: this.$t('field.practitioner'),
              formatter: value => {
                  return value.firstName + ' ' + value.lastName
              },
              link: true
            },
            {
                key: 'category',
                label: this.$t('field.category'),
            },
            {
              key: 'address',
              label: this.$t('field.address'),
              formatter: value => {
                return value.streetNumber + ' ' + value.streetName + ', ' + value.zipCode + ' ' + value.city
              }
            },
          ],
        },
        apiLink: "/practitioners/",
      }
    },
    setup() {
      const store = usePractitionerStore()
      const { practitioners, totalPages, loading } = storeToRefs(store)
      const { fetchAll } = store

      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      return { practitioners, totalPages, loading, fetchAll, isAdmin }
    },
    mounted() {
      // watch the params of the route to fetch the data again
      /*this.$watch(
        () => {*/
          this.fetchData()
        /*},
        // fetch the data when the view is created and the data is
        // already being observed
        //{ immediate: true }
      )*/
    },
    watch: {
      category() {
        this.fetchData()
      },
      sizeValue() {
        this.fetchData()
      }
    },
    methods: {
      getRequestParams() {
        let params = {}
        if(this.category) {
          params["category"] = this.category
        }
        if(this.page) {
          params["page"] = this.page - 1
        }
        if(this.size) {
          params["size"] = this.size
        }
        return params
      },
      async fetchData() {
        const params = this.getRequestParams()
        this.fetchAll(params)
      },
      handlePageChange(value) {
        this.page = value
        this.fetchData()
      },
      handlePageSizeChange(size) {
        this.size = size
        this.page = 1
        this.fetchData()
      },
      setActivePage(index) {
        console.debug("index = " + index)
        this.page = index
        this.fetchData()
      },
      createNew() {
        console.debug("creating a new practitioner...")
        this.$router.push("/practitioners/0")
        //this.$router.push({path: '/practitioners', query:{id: "0"}})
      },
      forceUpdate() {
        //this.$forceUpdate()
        this.fetchData()
      }
    },
    beforeRouteEnter(to, from, next) {
      const refresh = to.query.refresh || false
      if(refresh) {
        next(vm => {
          vm.forceUpdate()
        })
      } else {
        next()
      }
    }
  }
</script>