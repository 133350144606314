<template>
  <router-view v-slot="{Component}">
    <transition name="fade" mode="out-in">
      <div>
        <component :is="Component" />
      </div>
    </transition>
  </router-view>
</template>

<script>
  export default {
    name: 'ErrorContent'
  }
</script>
