import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import OrganizationService from '@/services/organization.service'

const emptyAddress = {
    streetNumber: '',
    streetName: '',
    city: '',
    country: '',
    zipCode: ''
}

const initialState = {
    id: '',
    name: '',
    active: '',
    category: '',
    specializations: '',
    address: emptyAddress,
    telecom: [],
    partOf: {
        name: '',
        url: ''
    },
    contacts: [],
    extraDetails: '',
    age: '',
    horaire: ''
}

export const useOrganizationStore = defineStore('organization', {
    state: () => ({
        organizations: [],
        totalPages: 0,
        orgs: [],
        organization: JSON.parse(JSON.stringify(initialState)),
        id: 0,
        loading: false
    }),
    getters: {
        getOrganizationsPerCategory: (state) => {
            return (category) => state.organizations.filter((organization) => organization.category === category)
        },
        // computed properties...
    },
    actions: {
        reset() {
            this.organization = JSON.parse(JSON.stringify(initialState))
        },
        async fetchAll(params = {}) {
            this.organizations = []
            this.totalPages = 0
            this.loading = true
            await OrganizationService.getAll(params)
                .then(response => {
                    this.organizations = response.data.organizations
                    this.totalPages = response.data.totalPages
                    if(!this.organizations.length) {
                        let message = i18n.t('message.empty-list')
                        useAlertStore().info(message)
                    }
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async fetchList() { // TODO: to be adapted and provide only 2 fields : id and name
            let params = { "format": "list" }
            await OrganizationService.getAll(params)
                .then(response => {
                    const result = response.data //.organizations
                    let mylist = []
                    result.forEach(element => {
                        mylist.push({url: '/api/organizations/' + element.id, name: element.name})
                    })
                    this.orgs = mylist
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async fetchById(id) {
            this.loading = true
            await OrganizationService.getById(id)
                .then(response => {
                    this.organization = response.data
                    if(!this.organization.address) {
                        this.organization.address = emptyAddress
                    }
                    if(!this.organization.contact) {
                        this.organization.contact = []
                    }
                    if(!this.organization.telecom) {
                        this.organization.telecom = []
                    }              
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async save(organization) {
            await OrganizationService.save(organization)
                .then((response) => {
                    this.organization = response.data
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async update(id, organization) {
            await OrganizationService.update(id, organization)
                .then((response) => {
                    this.organization = response.data
                    let message = i18n.t('message.organization.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async updateAddress(id, address) {
            await OrganizationService.updateAddress(id, address)
                .then((response) => {
                    this.organization = response.data
                    let message = i18n.t('message.address.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async addContact(id, contact) {
            await OrganizationService.addContact(id, contact)
                .then((response) => {
                    this.organization = response.data
                    let message = i18n.t('message.contact.added')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async updateContact(id, contact) {
            await OrganizationService.updateContact(id, contact)
                .then((response) => {
                    this.organization = response.data
                    let message = i18n.t('message.contact.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async removeContact(id, contact) {
            await OrganizationService.removeContact(id, contact)
                .then((response) => {
                    this.organization = response.data
                    let message = i18n.t('message.contact.removed')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async updateTelecom(id, telecom) {
            await OrganizationService.updateTelecom(id, telecom)
                .then((response) => {
                    this.organization = response.data
                    let message = i18n.t('message.telecom.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async remove(id) {
            await OrganizationService.removeById(id)
            .then(() => {
                let message = i18n.t('message.organization.removed')
                useAlertStore().success(message)
            })
            .catch(error => {
                    useAlertStore().error(error)
                })
        },
    },
})