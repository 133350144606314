<template>
    <div class="modal fade" ref="telecomModal" id="telecomModal" tabindex="-1" role="dialog" aria-labelledby="telecomModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div class="card card-plain">
                        <div class="card-header pb-0 text-left">
                            <h3 class="font-weight-bolder text-primary text-gradient">{{  $t('modal.telecom.title') }}</h3>
                            <p class="mb-0">{{  $t('modal.telecom.subtitle') }}</p>
                        </div>
                        <div class="card-body pb-3">
                            <form role="form text-left">
                                <label for="telecom-system">{{  $t('field.system') }}</label>
                                <div class="input-group mb-3">
                                    <select id="telecom-system" class="form-control form-control-sm" v-model="telecom.system">
                                        <option disabled value="">{{ $t('placeholder.system') }}</option>
                                        <option v-for="item in enum_system" :key="item" :value="item.key">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>
                                <label for="telecom-value">{{  $t('field.value') }}</label>
                                <div class="input-group mb-3">
                                    <input type="text"
                                        class="form-control form-control-sm"
                                        id="telecom-value"
                                        v-bind:placeholder="$t('placeholder.value')"
                                        aria-abel="Value"
                                        aria-describedby="addon-right addon-left"
                                        v-model="telecom.value"
                                    />
                                </div>
                                <label for="telecom-use">{{  $t('field.use') }}</label>
                                <div class="input-group mb-3">
                                    <select id="telecom-use" class="form-control form-control-sm" v-model="telecom.use">
                                        <option disabled value="">{{ $t('placeholder.use') }}</option>
                                        <option v-for="item in enum_use" :key="item" :value="item.key">
                                            {{ item.value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="text-right">
                                    <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">{{  $t('button.close') }}</button>
                                    <button v-if="upsert" type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="addTelecom">{{  $t('button.add') }}</button>
                                    <button v-else type="button" class="btn bg-gradient-primary" data-bs-dismiss="modal" @click="updateTelecom">{{  $t('button.update') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
    name: 'ModalTelecom',
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        upsert: {
            type: Boolean,
            default: false
        }
    },
    emits: ['add:telecom', 'update:telecom'],
    computed: {
        telecom: {
            get() {
                return this.modelValue
            }
        }
    },
    data() {
        return {
            enum_system: [
                { key: 'PHONE', value: this.$t('value.PHONE') },
                { key: 'FAX', value: this.$t('value.FAX') },
                { key: 'EMAIL', value: this.$t('value.EMAIL') },
                { key: 'MOBILE', value: this.$t('value.MOBILE') },
                { key: 'PAGER', value: this.$t('value.PAGER') },
                { key: 'URL', value: this.$t('value.URL') },
                { key: 'SMS', value: this.$t('value.SMS') },
                { key: 'OTHER', value: this.$t('value.OTHER') }
            ],
            enum_use: [
                { key: 'HOME', value: this.$t('value.HOME') },
                { key: 'WORK', value: this.$t('value.WORK') },
                { key: 'TEMP', value: this.$t('value.TEMP') },
                { key: 'OLD', value: this.$t('value.OLD') },
                { key: 'MOBILE', value: this.$t('value.MOBILE') },
                { key: 'PRIVATE', value: this.$t('value.PRIVATE') },
                { key: 'PUBLIC', value: this.$t('value.PUBLIC') }
            ]
        }
    },
    methods: {
        show() {
            let modal = new Modal(this.$refs.telecomModal)
            modal.show()
        },
        addTelecom() {
            this.$emit('add:telecom', this.telecom)
        },
        updateTelecom() {
            this.$emit('update:telecom', this.telecom)
        }
    }
}
</script>
