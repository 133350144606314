import { useErrorStore } from "@/stores/error.store"

export default class ErrorService {
    constructor() {
        //this.initHandler()
    }

    static initHandler() {
        const scope = this
        window.onerror = function(message, url, lineno, colno, error) {
            console.log(error, "test")
            if(error) {
                scope.onError(error)
                console.log(message, url, lineno, colno, error)
            }
        }
    }

    static onError(error, vm, info) {
        const response = error.response
        if(response && response.status >= 400 && response.status < 500) {
            console.error("HTTP error", response)
            return false
        }
        ErrorService.log(error, vm, info)
    }

    static displayErrorAlert(message) {
        alert(message)
    }

    static log(error, vm, info) {
        console.error("Global error:", error)
        console.error("Global context:", vm)
        console.error("Context info:", info)
        const errorStore = useErrorStore()
        errorStore.notify(error)
    }

}