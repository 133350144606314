<template>
    <div v-if="isAdmin" class="card mb-4">
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-6 d-flex align-items-center">
                    <p class="text-uppercase text-sm mb-0">{{  $t('section.sms') }}</p>
                </div>
            </div>
        </div>
        <div class="card-body py-2">
            <label for="phone" class="control-label">{{  $t('field.numbers') }}</label>
            <select id="phone" class="form-select form-select-sm" autocomplete="false"
                    :multiple="false"
                    v-model="mobile"
                    @input="selectPhone"
                    >
                <option v-if="placeholder" disabled value="">{{ placeholder }}</option>
                <option v-for="item in props.numbers" :key="item" :value="item.value">
                    {{ item.value }}
                </option>
            </select>
            <textarea id="message" type="text" rows="6"
                v-bind:placeholder="$t('placeholder.sms')"
                class="form-control mt-2"
                aria-describedby="addon-right addon-left"
                v-model="message"
            />
            <div class="row">
                <div class="col-md-12 text-right">
                    <button type="submit" class="btn btn-info mt-2" @click.prevent="send">
                        {{  $t('button.send') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, defineProps } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useAuthStore } from '@/stores/auth.store'
    import { useSmsStore } from '@/stores/sms.store'

    const authStore = useAuthStore()
    const { isAdmin } = storeToRefs(authStore)

    const props = defineProps({
        numbers: Array,
        placeholder: String
    })

    const mobile = ref('')
    const message = ref('')
    const smsStore = useSmsStore()
    const send = () => {
        console.log(mobile.value, message.value)
        smsStore.send(mobile.value, message.value)
            .then(() => { message.value = '' })
            .catch(() => {})
    }
    const selectPhone = (event) => {
        mobile.value = event.target.value
    }
</script>

<script>
export default {
    name: 'CardMessage',
    components: {
    },
    /*setup() {
      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      console.log(isAdmin)

      const mobile = ref('toto')
      const message = ref('')
      const smsStore = useSmsStore()
      const send = () => {
        smsStore.send(mobile, message)
            .then(() => { message.value = '' })
            .catch(() => {})
      }

      return { isAdmin, mobile, message, send }
    },
    methods: {
        selectPhone(event) {
            //set mobile to this value
            console.debug("value = " + event.target.value)
            this.mobile = event.target.value
            console.debug("value = " + this.mobile)
        }
    }*/
}
</script>
