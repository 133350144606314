<template>
    <div class="card mb-4">
        <div class="card-header pb-0">  
            <div class="row">
                <div class="col-6 d-flex align-items-center">
                    <p class="text-uppercase text-sm mb-0">{{  $t('section.contact') }}</p>
                </div>
                <div v-if="isAdmin" class="col-6 text-end">
                    <button v-if="id" type="button" class="btn btn-outline-primary btn-sm m-0"
                            data-bs-toggle="modal" data-bs-target="#contactModal"
                            @click.prevent="openModal">
                        <font-awesome-icon icon="fa fa-plus" /> {{ $t('button.add') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body py-2 mb-2">
            <ListContact class="table-hover"
                @edit:contact="edit"
                @remove:contact="$emit('remove:contact', $event)"
                :data="modelValue"
            />
            <ModalContact ref="contactModal"
                @add:contact="add"
                @update:contact="update"
                v-model="contact"
            />
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth.store'

import ListContact from '@/components/List/ListContact.vue'
import ModalContact from '@/components/Modals/ModalContact.vue'

const initialState = {
    purpose: '',
    firstName: '',
    lastName: '',
    telecom: []
}

export default {
    name: 'CardContact',
    components: {
        ListContact,
        ModalContact
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: ['add:contact', 'update:contact', 'remove:contact'],
    data() {
        return {
            contact: initialState,
            oldValue: null,
        }
    },
    setup() {
        const authStore = useAuthStore()
        const { isAdmin } = storeToRefs(authStore)

        return { isAdmin }
    },
    methods: {
        openModal() {
            this.contact = JSON.parse(JSON.stringify(initialState))
        },
        edit(contact, oldValue) {
            this.contact = contact
            this.oldValue = oldValue
            this.$refs.contactModal.show()
        },
        add() {
            this.$emit('add:contact', this.contact)
        },
        update() {
            this.$emit('update:contact', this.contact, this.oldValue)
        }
    }
}
</script>
