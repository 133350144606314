import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import SpecializationService from '@/services/specialization.service'

export const useSpecializationStore = defineStore('specialization', {
    state: () => ({
        specializations: []
    }),
    getters: {
        getData: (state) => state.specializations,
        // computed properties...
    },
    actions: {
        fetchList() {
            //if(!this.getLocal()) {
                this.getPersistent()
            //}
        },
        getLocal() {
            if(this.specializations.length == 0) {
                this.specializations = JSON.parse(sessionStorage.getItem('specializations')) || []
            }
            return this.specializations.length > 0
        },
        async getPersistent() {
            if(this.specializations.length == 0) {
                await SpecializationService.getAll()
                .then(response => {
                    this.specializations = response.data || []
                    sessionStorage.setItem('specializations', JSON.stringify(this.specializations))
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
            }
            return this.specializations.length > 0
        },
        async fetchAll() {
            this.specializations = []
            await SpecializationService.getAll()
                .then(response => {
                    this.specializations = response.data || []
                    if(!this.specializations.length) {
                        let message = i18n.t('message.empty-list')
                        useAlertStore().info(message)
                    }
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async save(specialization) {
            await SpecializationService.save(specialization)
                .then((response) => {
                    this.specializations.push(response.data)
                    let message = i18n.t('message.specialization.added')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async update(id, specialization) {
            await SpecializationService.update(id, specialization)
                .then((response) => {
                    this.specializations = this.specializations.map(item => {
                        if(item.id === id) {
                          return response.data
                        }
                        return item
                    })
                    let message = i18n.t('message.specialization.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async removeById(id) {
            await SpecializationService.removeById(id)
                .then(() => {
                    this.specializations = this.specializations.filter(item => item.id !== id)
                    let message = i18n.t('message.specialization.removed')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        }
    }
})