<template>
    <ul class="list-group">
        <li v-for="(item, index) in data" :key="index" class="list-group-item border-0 d-flex justify-content-between px-0 border-radius-lg">
            <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark font-weigth-bold text-sm">{{ itemValue(item, 'org') }}</h6>
                <span class="text-xs">{{ itemValue(item, 'service') }}</span>
            </div>
            <div class="d-flex align-items-end text-sm">
                <button type="button" class="btn btn-link text-secondary m-0 btn-info" @click="edit(index)">
                    <font-awesome-icon icon="fa fa-edit" />
                </button>
                <button type="button" class="btn btn-link text-secondary m-0 btn-danger" @click="remove(index)">
                    <font-awesome-icon icon="fa fa-times" />
                </button>
            </div>
        </li>
    </ul>
</template>
  
<script>
    export default {
      name: 'ListManagingEntity',
      props: {
        data: Array,
      },
      data() {
        return {
          oldManagingEntity: null
        }
      },
      emits: ['edit:managingEntity', 'remove:managingEntity'],
      methods: {
        itemValue (item, key) {
          switch(key) {
            case 'org':
              return item?.organization?.name
            case 'service':
              return item?.healthcareService?.name
          }
          return ''
        },
        edit(index) {
          let oldManagingEntity = this.data[index]
          let managingEntity = JSON.parse(JSON.stringify(this.data[index]))
          this.$emit('edit:managingEntity', managingEntity, oldManagingEntity)
        },
        remove(index) {
          let managingEntity = this.data[index]
          this.$emit('remove:managingEntity', managingEntity)
        }
      }
    }
</script>