import api from "./api";

class HealthcareServiceService {

    async getAll(params) {
        return api.get('/api/healthcareServices', {
            params: params
        })
    }

    async getById(id) {
        return api.get('/api/healthcareServices/' + id)
    }

    async save(service) {
        return api.post('/api/healthcareServices', service)
    }

    async update(id, service) {
        return api.put('/api/healthcareServices/' + id, service)
    }

    // adressing address

    async updateAddress(id, address) {
        return api.put('/api/healthcareServices/' + id + '/address', address)
    }

    // addressing contact through one single value

    async addContact(id, contact) {
        return api.patch('/api/healthcareServices/' + id + '/contacts', contact)
    }

    async updateContact(id, contact) {
        return api.patch('/api/healthcareServices/' + id + '/contacts', contact)
    }

    async removeContact(id, contact) {
        return api.delete('/api/healthcareServices/' + id + '/contacts/' + contact.id)
    }

    // adressing telecom through value list

    async updateTelecom(id, telecom) {
        return api.put('/api/healthcareServices/' + id + '/telecom', telecom)
    }

    async removeTelecom(id) {
        return api.delete('/api/healthcareServices/' + id + '/telecom')
    }

    async removeById(id) {
        return api.delete('/api/healthcareServices/' + id)
    }

}

export default new HealthcareServiceService()