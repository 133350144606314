<template>
  <ul class="list-group">
    <li v-for="(item, index) in data" :key="index" class="list-group-item border-0 d-flex justify-content-between px-0 border-radius-lg">
      <div class="d-flex flex-column">
        <h6 class="mb-1 text-dark font-weigth-bold text-sm">{{ itemValue(item, 'firstName') + ' ' + itemValue(item, 'lastName') }}</h6>
        <span class="text-xs">{{ itemValue(item, 'purpose') }}</span>
        <span v-for="(telecom, j) in filteredTelecom(item)" :key="j" class="text-dark font-weigth-bold text-sm">
          {{ this.$t('value.' + telecom.system) }} : {{ telecom.value }}
        </span>
      </div>
      <div class="d-flex align-items-end text-sm">
        <button type="button" class="btn btn-link text-secondary m-0 btn-info" @click="edit(index)">
          <font-awesome-icon icon="fa fa-edit" />
        </button>
        <button type="button" class="btn btn-link text-secondary m-0 btn-danger" @click="remove(index)">
          <font-awesome-icon icon="fa fa-times" />
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'ListContact',
    props: {
      data: Array,
    },
    data() {
        return {
          oldContact: null
        }
      },
    emits: ['edit:contact', 'remove:contact'],
    methods: {
      itemValue (item, key) {
        return item[key]
      },
      filteredTelecom(item) {
        return item.telecom.filter((e) => e.use !== 'PRIVATE')
      },
      edit(index) {
        let oldContact = this.data[index]
        let contact = JSON.parse(JSON.stringify(oldContact))
        this.$emit('edit:contact', contact, oldContact)
      },
      remove(index) {
        let contact = this.data[index]
        this.$emit('remove:contact', contact)
      }
    }
  }
</script>