<template>
    <div class="card card-plain">
        <div class="card-header pb-0 text-start bg-gray-100">
            <h4 class="font-weight-bolder">{{  $t('form.password.title') }}</h4>
            <p class="text-sm mb-0">{{  $t('form.password.recovery') }}</p>
        </div>
        <div class="card-body">
            <form role="form">
                <div class="mb-3">
                    <label for="Email" class="control-label">{{  $t('field.email') }}</label>
                    <input type="text"
                            id="Email"
                            v-bind:placeholder="$t('placeholder.email')"
                            v-model="email"
                            class="form-control" />
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-lg btn-primary w-100 mb-0" @click.prevent="reset">
                        {{  $t('button.modify-password') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="card-footer text-center pt-0 px-lg-2 px-1">
            <p class="mb-4 text-sm mx-auto">
                {{  $t('form.password.account-exist') }}
                <a href="https://welcome.cpts-paris15.fr">{{  $t('form.password.connect') }}</a>
            </p>
        </div>
    </div>
</template>

<script>
import authService from '@/services/auth.service'
import { useAlertStore } from '@/stores/alert.store'

export default {
    data() {
        return {
            email: ''
        }
    },
    methods: {
        async reset () {
            await authService.pwdChangeRequest(this.email)
                .then(() => {
                    this.$router.push("/password/check-email")
                })
                .catch(error => {
                    const alertStore = useAlertStore()
                    if(error.response?.data) {
                        alertStore.error(error.response.data)
                        return
                    }
                    alertStore.error(error)
                })
        }
    }
}
</script>