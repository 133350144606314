<template>
  <ul class="list-group">
    <li v-for="(item, index) in data" :key="index" class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg list-hover">
      <router-link :to="itemLink(item)" class="w-100">
        <div class="row">
          <div class="col-md-12">
            <h6 class="mb-3 text-sm">{{ item.name }}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div><span class="text-dark mb-2 text-xs">{{ item.category }}</span></div>
            <div><span class="text-dark mb-2 text-xs">{{ value(item.address) }}</span></div>
          </div>
          <div class="col-md-4">
            <div class="ms-auto text-end">
              <p v-for="e in item.telecom" :key="e" class="text-secondary text-xs mb-0">
                {{ this.$t('field.' + e.system.toLowerCase()) + " : " + e.value }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'SearchResults',
    props: {
      columns: Array,
      data: Array,
      apiLink: String
    },
    methods: {
      hasValue(item, column) {
        return item[column.label.toLowerCase()] !== 'undefined'
      },
      itemLink(item) {
        try {
          if(item['endpoint'] != null) {
            return item['endpoint']
          }
          if(item['id'] != null) {
            return this.apiLink + item['id']
          }
        } catch(error) {
          console.error(error)
        }
        return '#'
      },
      value(item) {
        if(item) {
          return item.streetNumber + ' ' + item.streetName + ', ' + item.zipCode + ' ' + item.city
        }
        return ''
      },
      itemValue(item, column) {
        try {
          switch(column.key) {
            case "address":
            case "providedBy":
            case "contact":
              return column.formatter(item[column.key])
            case "practitioner":
              return column.formatter(item)
            default:
              return item[column.key]
          }
        } catch(error) {
          console.error(error)
        }
        return ''
      },
      deselectRows () {
        //$.my-table.value.deselectRows()
      },
    }
  }
</script>