<template>
  <loading v-model:active="loading"
          :can-cancel="false"
          :is-full-page="true" />

  <div class="row">
    <div class="col-12">
        <div class="card mb-4">
            <div class="card-header pb-0 p-3">
              <div class="row">
                <div class="col-6 d-felx align-items-center">
                  <h6>{{  $t('card.healthcareServices.title') }}</h6>
                </div>
                <div v-if="isAdmin" class="col-6 text-end">
                  <button type="submit" class="btn btn-primary btn-sm mb-0" @click.prevent="createNew()">
                    {{  $t('button.add') }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <select-collection
                          collection="organizations"
                          keyvalue="name"
                          v-bind:placeholder="$t('placeholder.providedBy')"
                          v-bind:label="$t('field.providedBy')"
                          v-model="providedBy" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <select-collection
                          collection="healthcareServiceCategories"
                          keyvalue="name"
                          v-bind:placeholder="$t('placeholder.healthcareServiceCategory')"
                          v-bind:label="$t('field.category')"
                          v-model="category" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <select-enum
                        :enumeration="pageSizes"
                        v-bind:label="$t('field.pageSizes')"
                        v-model="sizeValue" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive">
                <TableBase class="table-hover"
                        :columns="table.columns"
                        :data="healthcareServices"
                        :apiLink="apiLink">
                </TableBase>
              </div>
            </div>
            <div class="card-footer">
              <base-pagination :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="5"
                  @page-changed="setActivePage" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { storeToRefs } from 'pinia'
  import { useHealthcareServiceStore } from '@/stores/healthcareService.store'
  import { useAuthStore } from '@/stores/auth.store'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'
  import TableBase from '@/components/Tables/TableBase.vue'
  import SelectCollection from '@/components/Select/SelectCollection.vue'
  import SelectEnum from '@/components/Select/SelectEnum.vue'
  import BasePagination from '@/components/Pagination/BasePagination.vue'

  export default {
    components: {
      Loading,
      TableBase,
      SelectCollection,
      SelectEnum,
      BasePagination,
    },
    name: 'HealthcareServicesList',
    data () {
      return {
        providedBy: '',
        category: '',

        page: 1,
        sizeValue: '10',
        size: 10,
        pageSizes: [ 10, 20, 50 ],

        table: {
          columns: [
            {
              key: 'name',
              label: this.$t('field.healthcareService'),
              link: true,
              linkFormatter: value => {
                return value.id
              }
            },
            {
              key: 'providedBy',
              label: this.$t('field.providedBy'),
              formatter: value => {
                if(value) {
                  return value.name
                }
                return ''
              }
            },
            {
              key: 'contacts',
              label: this.$t('field.contact'),
              formatter: value => {
                if(value.length > 0) {
                  let item = value[0]
                  let result = item.firstName + ' ' + item.lastName
                  if(value.length > 1) {
                    result += '...'
                  }
                  return result
                }
                return ''
              }
            },
            {
              key: 'telecom',
              label: this.$t('field.telecom'),
              list: true
            },
          ],
        },
        apiLink: "/healthcareServices/",
      }
    },
    setup() {
      const store = useHealthcareServiceStore()
      const { healthcareServices, totalPages, loading } = storeToRefs(store)
      const { fetchAll } = store

      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      return { healthcareServices, totalPages, loading, fetchAll, isAdmin }
    },
    mounted() {
      this.fetchData()
    },
    watch: {
      providedBy() {
        this.fetchData()
      },
      category() {
        this.fetchData()
      },
      sizeValue() {
        this.fetchData()
      }
    },
    methods: {
      getRequestParams() {
        let params = {}
        if(this.providedBy) {
          params["providedBy"] = this.providedBy
        }
        if(this.category) {
          params["category"] = this.category
        }
        if(this.page) {
          params["page"] = this.page - 1
        }
        if(this.size) {
          params["size"] = this.sizeValue
        }
        return params
      },
      async fetchData() {
        const params = this.getRequestParams()
        this.fetchAll(params)
      },
      handlePageChange(value) {
        this.page = value
        this.fetchData()
      },
      handlePageSizeChange(size) {
        this.size = size
        this.page = 1
        this.fetchData()
      },
      setActivePage(index) {
        console.debug("index = " + index)
        this.page = index
        this.fetchData()
      },
      createNew() {
        console.debug("creating a new healthcareService...")
        this.$router.push('/healthcareServices/0')
      }
    },
  }
</script>