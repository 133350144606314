<template>
  <div class="notifications">
    <transition-group :name="transitionName"
                      :mode="transitionMode">
      <notification-banner
        v-for="notification in notifications"
        v-bind="notification"
        :clickHandler="notification.onClick"
        :key="notification.timestamp.getTime()"
        @close="removeNotification">
      </notification-banner>
    </transition-group>
  </div>
</template>

<script>
import NotificationBanner from './NotificationBanner.vue'

export default {
  name: 'NotificationList',
  components: {
    NotificationBanner
  },
  props: {
    transitionName: {
      type: String,
      default: 'list'
    },
    transitionMode: {
      type: String,
      default: 'in-out'
    },
    overlap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notifications: this.$notifications.state
    };
  },
  methods: {
    removeNotification(timestamp) {
      this.$notifications.removeNotification(timestamp);
    }
  },
  created() {
    this.$notifications.settings.overlap = this.overlap;
  },
  watch: {
    overlap: function(newVal) {
      this.$notifications.settings.overlap = newVal;
    }
  }
};
</script>
