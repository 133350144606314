import SideBar from './SideBar.vue'
import SidebarLink from './SidebarLink.vue'
import SidebarTitle from './SidebarTitle.vue'

const SidebarStore = {
  showSidebar: true,
  displaySidebar(value) {
    this.showSidebar = value
    if(value) {
      document.body.classList.add('g-sidenav-show', 'bg-gray-100')
      return
    }
    document.body.classList.remove('g-sidenav-show', 'bg-gray-100')    
  }
}

const SidebarPlugin = {

  install: (app) => {
    app.mixin({
      data () {
        return {
          sidebarStore: SidebarStore
        }
      },
      methods: {
        displaySidebar(value) {
          this.sidebarStore.displaySidebar(value)
        },
        toggleSidebar() {
          this.displaySidebar(!this.sidebarStore.showSidebar)
        },
        hideSidebar() {
          this.displaySidebar(false)  
        }
      }
    })

    app.config.globalProperties.$sidebar = SidebarStore

    app.component('side-bar', SideBar)
    app.component('sidebar-link', SidebarLink)
    app.component('sidebar-title', SidebarTitle)
  }
}

export default SidebarPlugin
