import { createApp }  from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import { router } from './router'

// Unleash feature flag plugin for GitLab
import { plugin as unleashPlugin, UnleashClient } from '@unleash/proxy-client-vue'

// Global error handler
import errorService from '@/services/error.service'

// i18n internationalization
import i18n from './helpers/i18n.js'

// Register service worker
import './registerServiceWorker'

// Global directives
//import GlobalDirectives from './globalDirectives'

// Global components used across the app
//import GlobalComponents from './globalComponents'

// Bootstrap & template css components
import CssDashboard from '@/assets/scss/farmed3-dashboard'
//import ArgonDashboard from "./argon-dashboard";

// Global plugins used across the app
import { LoadingPlugin } from 'vue-loading-overlay'
import NotificationPlugin from '@/plugins/NotificationPlugin'
import SideBarPlugin from '@/plugins/SidebarPlugin'

// FontAwsome library icons
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

// 3rd party components used across the app
//import {API_KEY} from './pages/Maps/API_KEY'
//import vueGoogleMaps from '@fawmi/vue-google-maps'
import vueGoogleMaps from 'vue-google-maps-community-fork'

// Axios interceptor implementation
import interceptors from '@/services/axiosInterceptors'
interceptors()

library.add(fas, faSpinner, faFacebook, faTwitter, faInstagram)

const pinia = createPinia()
const app = createApp(App)

const unleashConfig = {
    url: process.env.VUE_APP_UNLEASH_URL,
    clientKey: process.env.VUE_APP_UNLEASH_CLIENTKEY,
    refreshInterval: 5*60,
    appName: process.env.VUE_APP_UNLEASH_APP_NAME,
    environment: process.env.VUE_APP_UNLEASH_ENVIRONMENT
}

const client = new UnleashClient(unleashConfig)

app.config.devtools=false
app.config.errorHandler = (err, vm, info) => errorService.onError(err, vm, info)

app
    .use(router)
    .use(pinia)
    .use(i18n)

    //.use(GlobalDirectives)
    .use(CssDashboard)
    //.use(ArgonDashboard)
    .use(LoadingPlugin)
    .use(NotificationPlugin)
    .use(SideBarPlugin)

    .use(vueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLEMAPS_KEY,
            libraries: ['places', 'visualization']
        }
    })

    .use(unleashPlugin, { unleashClient: client })

    .component("font-awesome-icon", FontAwesomeIcon)

    .mount('#app')

//import 'bootstrap/dist/js/bootstrap.js'