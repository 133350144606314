<template>
    <ul class="list-group">
        <li v-for="(item, index) in data" :key="index" class="list-group-item border-0 d-flex justify-content-between px-0 border-radius-lg">
            <div class="d-flex flex-column overflow-hidden">
                <h6 class="mb-1 text-dark font-weigth-bold text-sm">{{ itemValue(item, 'value') }}</h6>
                <span class="text-xs">{{ $t('value.' + itemValue(item, 'use')) }}</span>
            </div>
            <div class="d-flex align-items-end text-sm">
                <button type="button" class="btn btn-link text-secondary m-0 btn-info" @click="edit(index)">
                    <font-awesome-icon icon="fa fa-edit" />
                </button>
                <button type="button" class="btn btn-link text-secondary m-0 btn-danger" @click="remove(index)">
                    <font-awesome-icon icon="fa fa-times" />
                </button>
            </div>
        </li>
    </ul>
</template>
  
<script>
    export default {
      name: 'ListTelecom',
      props: {
        data: Array,
      },
      data() {
        return {
          oldTelecom: null
        }
      },
      emits: ['edit:telecom', 'remove:telecom'],
      methods: {
        itemValue (item, key) {
            return item[key]
        },
        edit(index) {
          let oldTelecom = this.data[index]
          let telecom = JSON.parse(JSON.stringify(this.data[index]))
          this.$emit('edit:telecom', telecom, oldTelecom)
        },
        remove(index) {
          let telecom = this.data[index]
          this.$emit('remove:telecom', telecom)
        }
      }
    }
</script>