<template>
    <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: isInFirstPage }" @click="onClickFirstPage">
                <a class="page-link" aria-label="First">
                    <font-awesome-icon :icon="['fas', 'angle-double-left']" size="sm" />
                    <span class="sr-only">{{ $t('button.first') }}</span>
                </a>
            </li>
            <li class="page-item" :class="{ disabled: isInFirstPage }" @click="onClickPreviousPage">
                <a class="page-link" aria-label="Previous">
                    <font-awesome-icon :icon="['fas', 'angle-left']" size="sm" />
                    <span class="sr-only">{{ $t('button.previous') }}</span>
                </a>
            </li>
            <li v-for="page in pages" :key="page.name" class="page-item" :class="{ active: isPageActive(page.name) }"  :disabled="page.isDisabled" @click="onClickPage(page.name)">
                <a class="page-link">
                    {{ page.name }}
                </a>
            </li>
            <li class="page-item" :class="{ disabled: isInLastPage }" @click="onClickNextPage">
                <a class="page-link" aria-label="Next">
                    <font-awesome-icon :icon="['fas', 'angle-right']" size="sm" />
                    <span class="sr-only">{{ $t('button.next') }}</span>
                </a>
            </li>
            <li class="page-item" :class="{ disabled: isInLastPage }" @click="onClickLastPage">
                <a class="page-link" aria-label="Last">
                    <font-awesome-icon :icon="['fas', 'angle-double-right']" size="sm" />
                    <span class="sr-only">{{ $t('button.last') }}</span>
                </a>
            </li>
        </ul>
    </nav>
</template>
  
<script>
export default {
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },    
        totalPages: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    computed: {
        startPage() {
            // When on first page
            if(this.currentPage === 1) {
                return 1;
            }
            // When on last page
            if(this.currentPage === this.totalPages) {
                return Math.max(1, this.totalPages - (this.maxVisibleButtons - 1));
            }
            // When in between
            return Math.max(1, Math.min(this.currentPage - 1, this.totalPages - (this.maxVisibleButtons - 1)))
        },
        pages() {
            const range = []
            for(let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage
                });
            }
            return range
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        }
    },
    methods: {
        onClickFirstPage() {
            if(this.currentPage === 1) {
                return;
            }
            this.$emit('page-changed', 1);
        },
        onClickPreviousPage() {
            if(this.currentPage === 1) {
                return;
            }
            this.$emit('page-changed', this.currentPage - 1);
        },
        onClickPage(page) {
            if(this.currentPage === page) {
                return;
            }
            this.$emit('page-changed', page);
        },
        onClickNextPage() {
            if(this.currentPage === this.totalPages) {
                return;
            }
            this.$emit('page-changed', this.currentPage + 1);
        },
        onClickLastPage() {
            if(this.currentPage === this.totalPages) {
                return;
            }
            this.$emit('page-changed', this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        }
    }
}
</script>