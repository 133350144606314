<template>
<div class="row">
    <div class="col-md-12">
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-12 text-end">
                        <button type="submit" class="btn btn-primary btn-sm mb-0" @click.prevent="reset">
                            {{  $t('button.reset') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">{{  $t('field.collection') }}</label>
                                    <div class="form-check">
                                        <input class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="radioDefault1"
                                                v-model="collection"
                                                value="Practitioner">
                                        <label class="font-weight-normal" for="radioDefault1">
                                            {{  $t('field.practitioner') }}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="radioDefault2"
                                                v-model="collection"
                                                value="HealthcareService">
                                        <label class="font-weight-normal" for="radioDefault2">
                                            {{  $t('field.healthcareService') }}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="radioDefault3"
                                                v-model="collection"
                                                value="Organization">
                                        <label class="font-weight-normal" for="radioDefault3">
                                            {{  $t('field.organization') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="category" class="control-label">{{  $t('field.category') }}</label>
                                    <select id="category" class="form-control form-control-sm"
                                            :class="{ 'border-danger': underlineCategory }"
                                            v-model="category">
                                        <option disabled value="">{{  $t('placeholder.category') }}</option>
                                        <option v-for="item in categories" :key="item">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <div v-show="underlineCategory"><span class="fs-8 text-danger">{{  $t('form.search.invalid-category') }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label class="control-label">{{  $t('field.address') }}</label>
                                    <GMapAutocomplete
                                            class="form-control form-control-sm"
                                            :class="{ 'border-danger': underlineLocation }"
                                            v-bind:placeholder="$t('placeholder.address')"
                                            @input="autocomplete($event)"
                                            @place_changed="setPlace"
                                        >
                                    </GMapAutocomplete>
                                    <div v-show="underlineLocation"><span class="fs-8 text-danger">{{  $t('form.search.invalid-location') }}</span></div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <SelectEnum
                                            v-bind:label="$t('field.distance')"
                                            v-bind:placeholder="$t('placeholder.distance')"
                                            v-bind:enumeration="distances"
                                            v-model="distance"
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import PractitionerCategoryService from '@/services/practitionerCategory.service'
import HealthcareServiceCategoryService from '@/services/healthcareServiceCategory.service'
import OrganizationCategoryService from '@/services/organizationCategory.service'

import SelectEnum from '@/components/Select/SelectEnum.vue'

export default {
    name: "CardFilteredSearch",
    components: {
        SelectEnum
    },
    props: {
        underlineCategory: {
            type: Boolean,
            default: false
        },
        underlineLocation: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            collection: '',
            categories: [],
            category: '',
            address: '',
            location: null,
            distances: [ '100', '200', '500', '1000' ],
            distance: '100',
        }
    },
    methods: {
        autocomplete(event) {
            this.address = event.target.value
        },
        async fetchData() {
            //this.loading = true
            let categoryService = null
            if(this.collection === 'Practitioner') {
                categoryService = PractitionerCategoryService
            } else if(this.collection === 'HealthcareService') {
                categoryService = HealthcareServiceCategoryService
            } else /*if(this.collection === 'Organization')*/ {
                categoryService = OrganizationCategoryService
            }
            await categoryService.getAll()
                .then(response => {
                    this.categories = response.data
                })
                .catch(error => {
                    console.error(error)
                })
            //this.loading = false
        },
        reset() {
            this.collection = ''
            this.category = ''
            this.location = null
            this.address = ''
            this.distance = '100'
        },
        setPlace(place) {
            this.location = place.geometry.location
        },
    },
    watch: {
        collection: function() {
            this.fetchData()
            this.$emit('set-collection-data', this.collection)
        },
        category: function() {
            this.$emit('set-category-data', this.category)
        },
        address: function() {
            this.$emit('set-address-data', this.address)
        },
        location: function() {
            this.$emit('set-location-data', this.location)
        },
        distance: function() {
            this.$emit('set-distance-data', this.distance)
        }
    }
}
</script>