import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import HealthcareServiceService from '@/services/healthcareService.service'

const emptyAddress = {
    streetNumber: '',
    streetName: '',
    city: '',
    country: '',
    zipCode: ''
}

const initialState = {
    id: '',
    active: '',
    providedBy: {
        name: '',
        url: ''
    },
    extraDetails: '',
    category: '',
    specializations: '',
    name: '',
    address: emptyAddress,
    comment : '',
    telecom: [],
    contacts: [],
    participants: [],
}

export const useHealthcareServiceStore = defineStore('healthcareService', {
    state: () => ({
        healthcareServices: [],
        totalPages: 0,
        services: [],
        healthcareService: JSON.parse(JSON.stringify(initialState)),
        id: 0,
        loading: false
    }),
    getters: {
        getHealthcareServicesPerCategory: (state) => {
            return (category) => state.healthcareServices.filter((healthcareService) => healthcareService.category === category)
        },
        // computed properties...
    },
    actions: {
        reset() {
            this.healthcareService = JSON.parse(JSON.stringify(initialState))
        },

        async fetchAll(params = {}) {
            this.healthcareServices = []
            this.totalPages = 0
            this.loading = true

            try {
                const response = await HealthcareServiceService.getAll(params)
                this.healthcareServices = response.data.healthcareServices
                this.totalPages = response.data.totalPages
                if(!this.healthcareServices.length) {
                    let message = i18n.t('message.empty-list')
                    useAlertStore().info(message)
                }
            }
            catch (error) {
                useAlertStore().error(error)
            }
            finally {
                this.loading = false
            }
        },

        async fetchList() { // TODO: to be adapted and provide only 2 fields : id and name
            let params = { "format": "list" }
            await HealthcareServiceService.getAll(params)
                .then(response => {
                    const result = response.data //.healthcareServices
                    let mylist = []
                    result.forEach(element => {
                        mylist.push({url: '/api/healthcareServices/' + element.id, name: element.name})
                    })
                    this.services = mylist
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async fetchById(id) {
            this.loading = true
            await HealthcareServiceService.getById(id)
                .then(response => {
                    this.healthcareService = response.data
                    if(!this.healthcareService.address) {
                        this.healthcareService.address = emptyAddress
                    }
                    if(!this.healthcareService.contact) {
                        this.healthcareService.contact = []
                    }
                    if(!this.healthcareService.telecom) {
                        this.healthcareService.telecom = []
                    }              
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },

        async save(healthcareService) {
            await HealthcareServiceService.save(healthcareService)
                .then((response) => {
                    this.healthcareService = response.data
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async update(id, healthcareService) {
            await HealthcareServiceService.update(id, healthcareService)
                .then((response) => {
                    this.healthcareService = response.data
                    let message = i18n.t('message.healthcareService.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async updateAddress(id, address) {
            await HealthcareServiceService.updateAddress(id, address)
                .then((response) => {
                    this.healthcareService = response.data
                    let message = i18n.t('message.address.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async addContact(id, contact) {
            await HealthcareServiceService.addContact(id, contact)
                .then((response) => {
                    this.healthcareService = response.data
                    let message = i18n.t('message.contact.added')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async updateContact(id, contact) {
            await HealthcareServiceService.updateContact(id, contact)
                .then((response) => {
                    this.healthcareService = response.data
                    let message = i18n.t('message.contact.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
          },

          async removeContact(id, contact) {
            await HealthcareServiceService.removeContact(id, contact)
                .then((response) => {
                    this.healthcareService = response.data
                    let message = i18n.t('message.contact.removed')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async updateTelecom(id, telecom) {
            await HealthcareServiceService.updateTelecom(id, telecom)
                .then((response) => {
                    this.healthcareService = response.data
                    let message = i18n.t('message.telecom.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },

        async remove(id) {
            await HealthcareServiceService.removeById(id)
            .then(() => {
                let message = i18n.t('message.healthcareService.removed')
                useAlertStore().success(message)
            })
            .catch(error => {
                useAlertStore().error(error)
                })
        }
    }
})