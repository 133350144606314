<template>
  <loading v-model:active="loading" :can-cancel="false" :is-full-page="true" />
  <div class="row">
    <div class="col-12">
        <div class="card mb-4">
            <div class="card-header pb-0 p-3">
              <div class="row">
                <div class="col-6 d-felx align-items-center">
                  <h6>{{  $t('card.organizations.title') }}</h6>
                </div>
                <div v-if="isAdmin" class="col-6 text-end">
                  <button type="submit" class="btn btn-primary btn-sm mb-0" @click.prevent="createNew()">
                    {{  $t('button.add') }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label for="group" class="control-label">{{ $t('field.group') }}</label>
                    <select class="form-control form-control-sm" disabled
                        id="group"
                        v-model="group">
                      <option disabled value="">{{ $t('placeholder.organizationGroup') }}</option>
                      <option value="HOSPITAL">{{ $t('value.hospital') }}</option>
                      <option value="HEALTHCENTER">{{ $t('value.healthcenter') }}</option>
                      <option value="PSYCHIATRY">{{ $t('value.psychiatry') }}</option>
                      <option value="PSYCHIATRY">{{ $t('value.psychiatry') }}</option>
                      <option value="OTHER">{{ $t('value.other') }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <select-collection
                        collection="organizationCategories"
                        v-bind:placeholder="$t('placeholder.organizationCategory')"
                        v-bind:label="$t('field.filter')"
                        v-bind:keyvalue="keyvalue"
                        v-bind:optionvalue="optionvalue"
                        v-bind:group="group"
                        v-model="category" />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <select-enum
                        :enumeration="pageSizes"
                        v-bind:label="$t('field.pageSizes')"
                        v-model="sizeValue" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive">
                <TableBase class="table-hover"
                        :columns="table.columns"
                        :data="organizations"
                        :apiLink="apiLink">
                </TableBase>
              </div>
            </div>
            <div class="card-footer">
              <base-pagination :totalPages="totalPages"
                  :currentPage="page"
                  :maxVisibleButtons="5"
                  @page-changed="setActivePage" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { storeToRefs } from 'pinia'
  import { useOrganizationStore } from '@/stores/organization.store'
  import { useAuthStore } from '@/stores/auth.store'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'
  import TableBase from '@/components/Tables/TableBase.vue'
  import SelectCollection from '@/components/Select/SelectCollection.vue'
  import SelectEnum from '@/components/Select/SelectEnum.vue'
  import BasePagination from '@/components/Pagination/BasePagination.vue'

  export default {
    components: {
      Loading,
      TableBase,
      SelectCollection,
      SelectEnum,
      BasePagination,
    },
    name: 'OrganizationsList',
    data () {
      return {
        activeIndex: 0,
        group: '',
        categories: {},
        category: '',
        filteredCategories: [],

        page: 1,
        sizeValue: '10',
        size: 10,
        pageSizes: [ 10, 20, 50 ],

        keyvalue: 'definition',
        optionvalue: 'definition',
        
        table: {
          columns: [
            {
              key: 'name',
              label: this.$t('field.name'),
              link: true
            },
            {
              key: 'category',
              label: this.$t('field.category'),
            },
            {
              key: 'address',
              label: this.$t('field.address'),
              formatter: value => {
                if(value) {
                  return value.streetNumber + ' ' + value.streetName + ', ' + value.zipCode + ' ' + value.city
                }
                return ''
              }
            },
            {
              key: 'contacts',
              label: this.$t('field.contact'),
              formatter: value => {
                if(value?.length > 0) {
                  let item = value[0]
                  let result = item.firstName + ' ' + item.lastName
                  if(value.length > 1) {
                    result += '...'
                  }
                  return result
                }
                return ''
              }
            },
          ],
          filtered: []
        },
        apiLink: "/organizations/",
      }
    },
    setup() {
      const store = useOrganizationStore()
      const { organizations, totalPages, loading } = storeToRefs(store)
      const { fetchAll } = store

      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      return { organizations, totalPages, loading, fetchAll, isAdmin }
    },
    mounted() {
      this.group = this.$route.query.category_group
      this.fetchData()
    },
    watch: {
      category() {
        this.fetchData()
      },
      sizeValue() {
        this.fetchData()
      }
    },
    methods: {
      getRequestParams() {
        let params = {}
        if(this.group) {
          params["group"] = this.group
        }
        if(this.category) {
          params["category"] = this.category
        }
        if(this.page) {
          params["page"] = this.page - 1
        }
        if(this.size) {
          params["size"] = this.sizeValue
        }
        return params
      },
      async fetchData() {
        const params = this.getRequestParams()
        this.fetchAll(params)
      },
      selectCollection(index = null) {
        if(!index) {
            this.category = this.filteredCategories[ this.activeIndex ]
        } else {
            this.category = this.filteredCategories[ index ]
        }
        this.attribute = this.category.name
        this.filteredCategories = []
        this.activeIndex = 0
        this.$emit('update:modelValue', this.category.id)
      },
      deselectRows () {
          this.value.deselectRows()
      },
      handlePageChange(value) {
        this.page = value
        this.fetchData()
      },
      handlePageSizeChange(size) {
        this.size = size
        this.page = 1
        this.fetchData()
      },
      setActivePage(index) {
        console.debug("index = " + index)
        this.page = index
        this.fetchData()
      },
      createNew() {
        console.debug("creating a new organization...")
        this.$router.push("/organizations/0")
      },
      forceUpdate() {
        //this.$forceUpdate()
        this.fetchData()
      }
    },
    beforeRouteEnter(to, from, next) {
      const refresh = to.query.refresh || false
      console.log(refresh)
      if(refresh) {
        next(vm => {
          vm.forceUpdate()
        })
      } else {
        next()
      }
    }
  }
</script>