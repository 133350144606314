<template>
  <footer class="footer pt-3">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="copyright text-left text-sm text-muted lg-start">
            © {{year}},
            designed by <a href="https://www.faresweb.net" class="font-weight-bold" target="_blank" rel="noopener">faresweb.net</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'FooterContent',
    data () {
      return {
        year: null
      }
    },
    created () {
      this.year = new Date().getFullYear()
    },
  }
</script>